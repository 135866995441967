import styles from "./NormalModeSidebar.module.css"
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import zoomInIcon from "../../images/zoom-in-icon.svg";
import homeIcon from "../../images/home-small-icon.svg";
import flagIcon from "../../images/flag-small-icon.svg";
import loupeIcon from "../../images/loupe-small-icon.svg";
import LevelError from "../../Entity/LevelError";
import { ClipLoader } from "react-spinners";
import useSound from "use-sound";
//@ts-ignore
import navigationSound from "../../assets/sounds/navigation.mp3";

const NormalModeSidebar =  observer(({normalGameStore}: any) => {

    const [playNavigation] = useSound(navigationSound, { volume: 0.5 });


    const onHintClick = () => {

        const remainingErrors: Array<LevelError> = [];

        if (normalGameStore.currentLevelAttempt?.hintCount < 1 && normalGameStore.isLevelCompleted !== true) {

            normalGameStore.currentLevel.levelErrors.forEach((levelError : LevelError) => {
                if(!normalGameStore.currentLevelAttempt.levelErrorsFound.find((levelErrorFound: LevelError) => levelErrorFound.levelErrorKey === levelError.levelErrorKey)){
                    remainingErrors.push(levelError);
                }
            });

            remainingErrors[0].useHint = true;
            normalGameStore.currentLevelAttempt.hintCount++;

            setTimeout(() => remainingErrors[0].useHint = false, 250);
        }
    }

    return(
        <div className={"mt-[116px] h-full " + (normalGameStore.isFullScreen && "hidden")}>  
            <div className={ "text-center m-auto " + (!normalGameStore.levelStore.levelsHaveLoaded ? "visible" : "hidden")}>
                <ClipLoader color="#c3c3c3" size="70" />
            </div> 
            <div className={"w-[305px] text-center flex flex-col items-center h-full " + (normalGameStore.levelStore.levelsHaveLoaded ? "visible" : "hidden")}>
                <span className="text-[75px] text-white font-FredokaOne">{normalGameStore.levelStore.levelsHaveLoaded ? normalGameStore.index + 1 + "/" + normalGameStore.levelStore.levels.length : "..."}</span>
                <div style={{wordBreak: "break-word"}} className="bg-white text-black text-[42px] p-3 rounded-[11px] leading-[34px] font-Mali font-bold w-[315px] min-h-[88px] flex justify-center items-center">
                    {normalGameStore.currentLevel?.title} 
                </div>

                <div className="w-[212px] mx-auto mt-[32px]">
                
                    <div className={"border-[3px] border-[#9FA250] rounded-[433px] h-[85px] " + 
                    (normalGameStore.currentLevelAttempt?.levelErrorsFound.length === normalGameStore.currentLevelAttempt?.level.levelErrors.length && "bg-[#C6C96E]")}>
                        <span className={"text-[55px] font-FredokaOne " + 
                        (normalGameStore.currentLevelAttempt?.levelErrorsFound.length === normalGameStore.currentLevelAttempt?.level.levelErrors.length ? "text-black" : "text-[#9FA250]")
                    }>
                        {normalGameStore.currentLevelAttempt?.levelErrorsFound.length}/{normalGameStore.currentLevelAttempt?.level.levelErrors.length}</span>
                    </div>

                    <div className="mt-[16px] flex text-white text-[22px] justify-center">
                        <img className="w-[20px] mr-[9px]" src={flagIcon} alt="" />
                        <span className="font-AvenirBlack">OBJECTIF</span>
                    </div>
                </div>

                <div onClick={() => {onHintClick(); playNavigation()}} className="w-[212px] mx-auto mt-[24px] cursor-pointer">
                    
                    <div className="border-[3px] border-[#51AABC] rounded-[433px] h-[85px] ">
                        <span className="text-[55px] text-[#51AABC] font-FredokaOne ">{normalGameStore.currentLevelAttempt !== null ? ( 1 - normalGameStore.currentLevelAttempt?.hintCount) : '' }</span>
                    </div>

                    <div className="mt-[16px] flex text-white text-[22px] justify-center">
                        <img className="w-[25px] mr-[7px]" src={loupeIcon} alt="" />
                        <span className="font-AvenirBlack">{normalGameStore.currentLevelAttempt !== null && normalGameStore.currentLevelAttempt?.hintCount > 1 ? "INDICES" : "INDICE"}</span>
                    </div>
                </div>

                <div className={styles.otherButtonsSidebar}>
                    <div onClick={ () => {normalGameStore.onToggleFullScreen(); playNavigation()}} className={styles.zoomInContainer} >
                        <img src={zoomInIcon} alt=""  className={styles.zoomInIcon} />
                        <p className={styles.zoomInText + " font-FredokaOne"}>PLEIN ÉCRAN</p>
                    </div>

                    <Link to={`/`} onClick={() => playNavigation()} className={styles.homeContainer} >
                        <img src={homeIcon} alt="" className={styles.homeIcon} />
                        <p className={styles.homeText + " font-FredokaOne"}>ACCUEIL</p>
                    </Link>

                </div>
            </div>
        </div>
    )
});

export default NormalModeSidebar