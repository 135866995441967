import Level from "../Entity/Level";
import {makeAutoObservable} from "mobx";
import LevelError from "../Entity/LevelError";
import BaseDS from "../API/BaseDS";
import UserStore from "./UserStore";
import LevelAttempt from "../Entity/LevelAttempt";

class LevelStore {

    levels: Array<Level> = [];
    allLevels: Array<Level> = [];
    levelsQuickGame: Array<Level> = [];
    isLoading: boolean = false;
    clipPathSize: number = 122;
    circleSizeError: number = 84;
    levelsHaveLoaded: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    initialize(){
        this.loadLevels();
    }

    loadLevels() {
        this.isLoading = true;
        console.log('GETTING THE RESPPONSES')

        Promise.all([
            BaseDS.getAllLevels(),
            BaseDS.getAllLevelErrors()
        ]).then((responses: any) => {

            console.log('GOT THE RESPONSES', responses)



            const [levels, levelErrors] = responses;
            const levelsSortedByPosition = levels.sort(function(a: any, b: any){
                return parseFloat(a.position) - parseFloat(b.position);
            });

            const hydratedLevels = levelsSortedByPosition.map((level: any) => {
                
                const associatedLevelErrors = levelErrors.filter((levelError: any) => levelError.levelId === level.levelId);
                level.levelErrors = associatedLevelErrors;

                return Level.hydrate(level);

            });

            this.allLevels = hydratedLevels;
            this.createQuickLevels();
            this.levels = hydratedLevels.filter((level: Level) => level.visible === "true");
            this.isLoading = false;
            this.levelsHaveLoaded = true;

        }).catch(() => console.log('oops'))
        
    }

    createQuickLevels() {

        this.allLevels.map((level: Level) => {

            level.levelErrors.forEach((error: LevelError) => {
                
                this.levelsQuickGame.push(new Level(
                    'Niveau ' + (Math.random() * 100).toFixed(),
                    '',
                    '', 
                    level.imageOriginal,
                    [error],
                    level.levelId,
                    "published",
                    true,
                    ""
                ))
            });

        });

    }

    get randomLevel() {
        return this.levelsQuickGame[Math.floor(Math.random() * this.levelsQuickGame.length)]
    }

    getRandomLevel(){
        return this.levelsQuickGame[Math.floor(Math.random() * this.levelsQuickGame.length)]
    }

    getRandomLevelForQuickGame(levelAttempts: Array<LevelAttempt>){
        if(levelAttempts.length === 0) return this.getRandomLevel();

        const idealLevelsToChooseFrom = this.levelsQuickGame.filter((level) => !((levelAttempts.map((levelAttempt) => levelAttempt.level.levelId)).includes(level.levelId)));

        if(idealLevelsToChooseFrom.length !== 0) return idealLevelsToChooseFrom[Math.floor(Math.random() * idealLevelsToChooseFrom.length)]

        const levelsToChooseFrom = this.levelsQuickGame.filter((level) => level.levelId !== levelAttempts[levelAttempts.length - 1].level.levelId);

        if(levelsToChooseFrom.length !== 0) return levelsToChooseFrom[Math.floor(Math.random() * levelsToChooseFrom.length)]

        return this.getRandomLevel();
    }
}

export default LevelStore;