
import './UserAnnouncement.scss';
import { ReactComponent as HeaderMobileBlocked } from './assets/homepage-header-phone.svg';
import { ReactComponent as MobileBlocked } from './assets/homepage-phone.svg';
import { ReactComponent as IOSBtn } from './assets/ios-btn.svg';
import { ReactComponent as AndroidBtn } from './assets/android-btn.svg';

function UserAnnouncement() {

  return (
    <div className='hp-userAnnouncements'>
      <div className='hp-userAnnouncements-wrapper'>
        <HeaderMobileBlocked className='hp-userAnnouncements-header' />
        <MobileBlocked className='hp-userAnnouncements-content' />
        <div className='hp-userAnnouncements-btn'>
          <a href="https://play.google.com/store/apps/details?id=com.bdouin.apps.muslimstrips"><AndroidBtn className='hp-userAnnouncements-btn-android' /></a>
          <a href="https://apps.apple.com/fr/app/bdouin-by-muslimshow/id946822771"><IOSBtn className='hp-userAnnouncements-btn-ios' /></a>
        </div>
      </div>
    </div>
  );
}

export default UserAnnouncement;
