import SVG from "Components/SVG";
import { ReactSVG } from "react-svg";
import { paymentInfo } from "./BlockedModal";

function PaymentHeader() {

  return (
    <>
      <div className="hp-blockedModal-popup__header">
        <ReactSVG src={paymentInfo.image} className="hp-blockedModal-popup__header-icon" />
        <div className="hp-blockedModal-popup__header-text">
          <div className="hp-blockedModal-popup__header-title">
            <b>{paymentInfo.title}</b>
          </div>
          <div className="hp-blockedModal-popup__header-desc">
            {paymentInfo.description}
          </div>
          <div className="hp-blockedModal-popup__header-content">
            <b>Contenu : </b>{paymentInfo.content}
          </div>
          <div className="hp-blockedModal-popup__header-state">
            <b>État : </b>{paymentInfo?.status}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentHeader;
