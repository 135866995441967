import { API, Auth } from 'aws-amplify';
import awsconfig from 'aws-exports'

const localUserPrefix: string = "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_z1Xt9rPHs,cognito-idp.eu-west-1.amazonaws.com/eu-west-1_z1Xt9rPHs:CognitoSignIn:";

async function getOptions(initialOptions: any, apiName: string) {
  const finalOptions = { ...initialOptions };
  if (!finalOptions['headers']) finalOptions['headers'] = {};

  const apiConfig = awsconfig.aws_cloud_logic_custom.filter(api => api.name === apiName);
  if (apiConfig.length && apiConfig[0].endpoint
    && (apiConfig[0].endpoint.indexOf('localhost') > -1 || apiConfig[0].endpoint.indexOf('192.') > -1)) {
    try {
      const userInfo = await Auth.currentUserInfo();
      finalOptions['headers']['cognitoAuthenticationType'] = 'authenticated';
      finalOptions['headers']['cognitoAuthenticationProvider'] = localUserPrefix + userInfo.attributes.sub;
    } catch (error: any) {
      finalOptions['headers']['cognitoAuthenticationType'] = 'unauthenticated';
    }
  }
  return finalOptions;
}

export default class ApiWrapper {

  static get = async (apiName: string, path: string, options?: any) => {
    return API.get(apiName, path, await getOptions(options, apiName));
  };

  static put = async (apiName: string, path: string, options: any) => {
    return API.put(apiName, path, await getOptions(options, apiName));
  };

  static post = async (apiName: string, path: string, options: any) => {
    return API.post(apiName, path, await getOptions(options, apiName));
  };

  static delete = async (apiName: string, path: string, options: any) => {
    return API.del(apiName, path, await getOptions(options, apiName));
  };

}