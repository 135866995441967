class CalculateScorePosition {

    static isToday = (someDate: Date) => {

        const today = new Date()
        let date = new Date(someDate);

        return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    }

    static isThisYear = (someDate: Date) => {

        const today = new Date()
        let date = new Date(someDate);

        return date.getFullYear() == today.getFullYear()
    }
}

  export default CalculateScorePosition;
