export default class ImageDataStore {

  static getUrl = (key: string) => {
    return `${process.env.REACT_APP_IMAGES_FOULANE_GAME_API_ENPOINT_BASE_URL}/${key}`;
  };  
  
  static getUrlFileFromHoopow = (key: string) => {
    return `${process.env.REACT_APP_HOOPOW_IMAGES_API_ENPOINT_BASE_URL}/${key}`;
  };

}

