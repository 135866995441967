import { API } from "aws-amplify";
import {
    makeAutoObservable,
    reaction
} from "mobx";
import useSound from "use-sound";
import BaseDS from "../API/BaseDS";
import loadImage from "../Common/loadImage";
import LevelAttempt from "../Entity/LevelAttempt";
import LevelError from "../Entity/LevelError";
import Novel from "../Entity/Novel";
import LevelStore from "./LevelStore";
import UserStore from "./UserStore";

class NormalGameStore {

    index: number = 0;
    levelStore: LevelStore;
    userStore: UserStore;
    levelAttempts: Array < LevelAttempt > = [];
    isLevelCompleted: boolean = false;
    isFullScreen: boolean = false;
    novel: any = null;
    isLoadingNovels: boolean = false;

    constructor(levelStore: LevelStore, userStore: UserStore) {
        makeAutoObservable(this);

        this.levelStore = levelStore;
        this.userStore = userStore;
    }

    initialize(){
        console.log(this.levelAttempts)
        this.levelAttempts = Array.isArray(this.userStore.user?.levelAttempts) ? this.userStore.user.levelAttempts : [];
        this.index = this.levelAttempts.length - 1;

        const saveLevelAttempt = (levelAttempt: LevelAttempt) => {
            const levelAttemptToStore = {
                level: levelAttempt.level,
                levelErrorsFound: levelAttempt.levelErrorsFound,
                hintCount: levelAttempt.hintCount
            }

            console.log('SAVING THIS LEVEL ATTEMPT', levelAttemptToStore)

            BaseDS.updateLevelAttempts(levelAttemptToStore);
        }

        const saveLevelAttempts = async () => {

            const allErrorsHaveBeenFound = this.levelStore.levelsHaveLoaded && this.currentLevelAttempt && (this.currentLevelAttempt.levelErrorsFound.length > 0) && this.currentLevelAttempt.levelErrorsFound.length === this.currentLevelAttempt.level.levelErrors.length;

            if (allErrorsHaveBeenFound) {

                this.novel = null;
                this.currentLevelAttempt && this.currentLevelAttempt.level.novel !== undefined && this.currentLevelAttempt.level.novel !== null && getNovelFromId();
            }

            const isLastAvailableLevel = this.index === this.levelStore.levels.length - 1;

            await new Promise(async (resolve, reject) => {
                if(allErrorsHaveBeenFound){
                    /*if (isLastAvailableLevel) {
                        //await BaseDS.updateLevelAttempts([]);
                    }
                    else {
                        if(this.currentLevelAttempt)
                            saveLevelAttempt(this.currentLevelAttempt);
                    }*/

                    if(this.currentLevelAttempt) saveLevelAttempt(this.currentLevelAttempt);

                    setTimeout(() => {this.showLevelCompletedScreen();this.generateNextLevel(); resolve(null);}, 1000);
                }else {
                    resolve(null);
                }
            })

            /*if (this.currentLevelAttempt && isLastAvailableLevel) {
                if (!allErrorsHaveBeenFound) {
                    saveLevelAttempt(this.currentLevelAttempt);
                }
            }
            else
            if(this.currentLevelAttempt) saveLevelAttempt(this.currentLevelAttempt);*/
        }

        const getNovelFromId = () => {

            this.isLoadingNovels = true;

            if(this.currentLevelAttempt && this.currentLevelAttempt.level.novel !== undefined && this.currentLevelAttempt.level.novel != null){
                API.get("PlatformDataStoreRestApi",`/video/getById/${this.currentLevelAttempt.level.novel}`, {}).then((video: any) => {
                    this.novel = Novel.hydrate(video)
                    this.isLoadingNovels = false;
                });
            }

        }

        reaction(() => this.currentLevelAttempt?.levelErrorsFound.length, saveLevelAttempts);
        reaction(() => this.currentLevelAttempt?.hintCount, saveLevelAttempts);

        const destroyer = reaction(() => (this.levelStore.levelsHaveLoaded), (levelsHaveLoaded) => {

            if(levelsHaveLoaded){
                if(this.currentLevelAttempt &&  this.currentLevelAttempt.levelErrorsFound.length === this.currentLevelAttempt.level.levelErrors.length){
                    if (this.index >= this.levelStore.levels.length - 1) {
                        //BaseDS.updateLevelAttempts([]);
                        this.showLevelCompletedScreen();
                    }else {
                        console.log('WERE ON FINISHED LEVEL AND THERE IS MORE, GENERATING NEXT LEVEL')
                        this.generateNextLevel();

                        if(this.currentLevelAttempt) {
                            console.log('SAVING CURRENT LEVEL ATTEMPT')
                            saveLevelAttempt(this.currentLevelAttempt);
                        }
                    }
                }
                this.startGame();
                destroyer();
            }
        })
    }

    preloadImages() {
        Promise.all([
            loadImage(this.lastLevelAttempt?.level.imageOriginal),
            ...(this.lastLevelAttempt?.level.levelErrors.map((error: LevelError) => {
                return loadImage(error.errorImage);
            }) || [])
        ])
            .then(() => {
                if(this.lastLevelAttempt)
                    this.lastLevelAttempt.allImagesAreLoaded = true;
            })
            .catch(err => console.log("Failed to load images", err))
    }

    startGame() {
        if(this.levelAttempts.length === 0){
            this.isLevelCompleted = false;
            this.generateAndGoToLastLevel();
        }else {
            this.preloadImages();
            this.goToLastLevel();
        }
    }

    restartLastLevel() {
        if (this.levelStore.levels.length === this.levelAttempts.length && this.currentLevelAttempt?.levelErrorsFound.length === this.currentLevelAttempt?.level.levelErrors.length) {
            this.levelAttempts[this.levelAttempts.length - 1].levelErrorsFound = [];
        }
    }

    restart() {
        this.levelAttempts = [];
        this.isLevelCompleted = false;
        this.index = 0;
        this.generateAndGoToLastLevel();
    }

    onToggleFullScreen() {
        this.isFullScreen = !(this.isFullScreen);
    }

    generateNextLevel(){

        if (this.levelStore.levels.length > this.levelAttempts.length && this.currentLevelAttempt?.levelErrorsFound.length === this.currentLevelAttempt?.level.levelErrors.length) {
            
            const generatedLevel = this.levelStore.levels[this.levelAttempts.length];
            const generatedLevelAttempt = new LevelAttempt(generatedLevel)
            console.log('ADDING A NEW LEVEL ATTEMPT, THIS ONE', generatedLevelAttempt, 'THESE WERE AVAILABLE', this.levelStore.levels)
            this.levelAttempts.push(generatedLevelAttempt);
        }
        this.preloadImages();
    }

    goToLastLevel(){
        this.index = this.levelAttempts.length - 1;
    }

    showLevelCompletedScreen(){
        this.isLevelCompleted = true;
    }

    hideLevelCompletedScreen(){
        this.isLevelCompleted = false;
        //this.lastLevelAttempt.isStarted = true;
    }

    generateAndGoToLastLevel(){
        this.generateNextLevel();
        this.goToLastLevel();
    }


    get currentLevelAttempt() {
        return (this.index <= this.levelAttempts.length - 1 && this.levelAttempts[this.index]) || null;
    }

    get lastLevelAttempt() {
        return (this.levelAttempts.length > 0 && this.levelAttempts[this.levelAttempts.length - 1]) || null;
    }

    get currentLevel() {
        return this.currentLevelAttempt?.level || null;
    }

    get currentLevelImagesAreLoaded(){
        return this.currentLevelAttempt?.allImagesAreLoaded || false;
    }

}

export default NormalGameStore;