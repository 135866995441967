import styles from "./QuickModeSidebar.module.css"
import stopwatch from "../../images/stopwatch.svg";
import zoomInIcon from "../../images/zoom-in-icon.svg";
import homeIcon from "../../images/home-small-icon.svg";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
//@ts-ignore
import navigationSound from "../../assets/sounds/navigation.mp3";
import useSound from "use-sound";

const QuickModeSidebar = observer(({quickGameStore}: any) => {

    const [playNavigation] = useSound(navigationSound, { volume: 0.5 });

    return(
        <>
            <div className="flex flex-col text-[62px] font-MarvinRound mt-[230px] mb-[29px] leading-[83px] tracking-[2px] text-white ">
                <span className="rotate-[-1deg]">PARTIE</span>
                <span className="">rapide</span>
            </div>

            <div className="flex justify-center items-center text-[#51AABC] text-[55px] rounded-[432px] border-[3px] w-[212px] h-[85px] border-[#51AABC] tracking-[4px]">
                <span className="font-FredokaOne">{quickGameStore.stringifyTimeRemaining}</span>    
            </div>
            <div className="flex items-center">
                <img src={stopwatch} className="mr-[7px] h-[30px]" alt="" />
                <span className="text-white text-[22px] leading-[69px] font-AvenirBlack">TEMPS RESTANT</span>
            </div>

            <div className={styles.otherButtonsSidebar}>
                <div onClick={ () => {quickGameStore.onToggleFullScreen(); playNavigation()}} className={styles.zoomInContainer} >
                    <img src={zoomInIcon} alt=""  className={styles.zoomInIcon} />
                    <p className={styles.zoomInText + " font-FredokaOne"}>PLEIN ÉCRAN</p>
                </div>

                <Link to={`/`} onClick={() => {quickGameStore.restart(); playNavigation()}} className={styles.homeContainer} >
                    <img src={homeIcon} alt="" className={styles.homeIcon} />
                    <p className={styles.homeText + " font-FredokaOne"}>ACCUEIL</p>
                </Link>
            </div>
        </>
    )
});

export default QuickModeSidebar