import { observer } from "mobx-react";
import LevelError from "../../Entity/LevelError";
import styles from './NormalMode.module.css';
import foulanePlayVideo from '../../images/foulane-play-video.svg';
import iconSmallPlayVideo from '../../images/icon-small-play-video.svg';
import roundedZoneSelection from '../../images/rounded-zone-selection.svg';
import zoomOut from '../../images/zoom-out.svg';
import { Link } from "react-router-dom";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { reaction } from "mobx";
import { ClipLoader } from 'react-spinners';
import useSound from "use-sound";
//@ts-ignore
import correctSound from "../../assets/sounds/correct.mp3";
//@ts-ignore
import incorrectSound from "../../assets/sounds/incorrect.mp3";
//@ts-ignore
import wonSound from "../../assets/sounds/won.mp3";
//@ts-ignore
import lostSound from "../../assets/sounds/lost.mp3";
//@ts-ignore
import navigationSound from "../../assets/sounds/navigation.mp3";
import { UserContext } from "Components/utils/UserContext";


export const NormalMode = observer(({ normalGameStore, userStore }: any) => {

    const { userHasAccess } = useContext(UserContext);
    const [playCorrect] = useSound(correctSound, { volume: 0.3 });
    const [playIncorrect] = useSound(incorrectSound, { volume: 1.3 });
    const [playWon] = useSound(wonSound, { volume: 0.1 });
    const [playLost] = useSound(lostSound, { volume: 0.3 });
    const [playNavigation] = useSound(navigationSound, { volume: 0.3 });
    const [scale, setScale] = useState(1);
    const [isSafeToDisplay, setIsSafeToDisplay] = useState(false);

    useEffect(() => {
        if (!userHasAccess()) document.location.href = "/"
    }, [])

    useLayoutEffect(() => {
        const destroyer = reaction(() => normalGameStore.currentLevelImagesAreLoaded, (allImagesAreLoaded) => {
            setIsSafeToDisplay(false)
            if (allImagesAreLoaded) {
                setTimeout(() => setIsSafeToDisplay(true), 1000);
            }
        }, { fireImmediately: true })
    }, [])

    let imagesContainerEl = useRef(null);
    let imageEl = useRef(null);
    let imagesSuperWrapperEl = useRef(null);
    let zoomOutEl = useRef(null);
    let descriptionEl = useRef(null);
    let subDescriptionEl = useRef(null);

    const scaleImages = () => {

        // @ts-ignore
        let imagesContainerElStyle: CSSStyleDeclaration = imagesContainerEl.current && getComputedStyle(imagesContainerEl.current);
        // @ts-ignore
        let imageElStyle: CSSStyleDeclaration = imageEl.current && getComputedStyle(imageEl.current);
        // @ts-ignore
        let zoomOutElStyle: CSSStyleDeclaration = zoomOutEl.current && getComputedStyle(zoomOutEl.current);
        // @ts-ignore        
        let descriptionElStyle: CSSStyleDeclaration = descriptionEl.current && getComputedStyle(descriptionEl.current);
        // @ts-ignore
        let subDescriptionElStyle: CSSStyleDeclaration = subDescriptionEl.current && getComputedStyle(subDescriptionEl.current);

        let imagesContainerSmallWidth;
        let imagesContainerSmallheight;

        if (imagesSuperWrapperEl.current != null) {
            // @ts-ignore
            imagesSuperWrapperEl.current.style.width = 'unset';
            // @ts-ignore
            imagesSuperWrapperEl.current.style.height = 'unset';

            if (normalGameStore.isFullScreen) {
                imagesContainerSmallWidth = parseFloat(imagesContainerElStyle?.width) - (parseFloat(imagesContainerElStyle?.paddingRight) + parseFloat(imagesContainerElStyle?.paddingLeft))
                    - (parseFloat(zoomOutElStyle?.width) * 2 + parseFloat(zoomOutElStyle?.marginRight) + parseFloat(zoomOutElStyle?.marginLeft));

                imagesContainerSmallheight = parseFloat(imagesContainerElStyle?.height) - parseFloat(imagesContainerElStyle?.paddingTop) - parseFloat(imagesContainerElStyle?.paddingBottom);
            } else {
                imagesContainerSmallWidth = parseFloat(imagesContainerElStyle?.width) - (parseFloat(imagesContainerElStyle?.paddingRight) + parseFloat(imagesContainerElStyle?.paddingLeft));
                imagesContainerSmallheight = parseFloat(imagesContainerElStyle?.height) - parseFloat(imagesContainerElStyle?.paddingTop)
                    - parseFloat(imagesContainerElStyle?.paddingBottom) - parseFloat(descriptionElStyle?.height) - parseFloat(subDescriptionElStyle?.height);
            }

            let imagesContainerBigWidth = parseFloat(imageElStyle?.width) * 2 + 24 /*parseFloat(imageElStyle?.marginRight)*/;
            let imagesContainerBigHeight = parseFloat(imageElStyle?.height)
            let imagesContainerHeightRatio = imagesContainerSmallheight / imagesContainerBigHeight;
            let imagesContainerWidthRatio = imagesContainerSmallWidth / imagesContainerBigWidth;
            let computedScale = Math.min(1, Math.min(imagesContainerWidthRatio, imagesContainerHeightRatio));

            // @ts-ignore
            imagesSuperWrapperEl.current.style.width = (imagesContainerBigWidth * computedScale) + 'px';
            // @ts-ignore  
            imagesSuperWrapperEl.current.style.height = (imagesContainerBigHeight * computedScale) + 'px';

            setScale(computedScale);
        }

    }

    useLayoutEffect(scaleImages, [imagesContainerEl, imageEl, imagesSuperWrapperEl, zoomOutEl, normalGameStore.isFullScreen]);

    const onErrorClick = (levelError: LevelError) => {

        if (!normalGameStore.currentLevelAttempt.levelErrorsFound.find((levelErrorFound: LevelError) => levelErrorFound.levelErrorKey === levelError.levelErrorKey)) {

            normalGameStore.currentLevelAttempt.addFoundLevelError(levelError);
            playCorrect();
        }

    };

    if (normalGameStore.currentLevelAttempt?.levelErrorsFound.length === normalGameStore.currentLevelAttempt?.level.levelErrors.length) {
        playWon();
    }

    return (
        <>
            <div className={"relative w-full h-full grow rounded-[16px] " + (normalGameStore.isFullScreen || !normalGameStore.levelStore.levelsHaveLoaded ? "bg-black" : "bg-[#1E3130] ")
            }>
                {
                    <div className={"absolute bg-black opacity-100 top-[-10px] left-[-10px] bottom-[-10px] right-[-10px] flex justify-center items-center " + (normalGameStore.currentLevel !== null && !normalGameStore.lastLevelAttempt.allImagesAreLoaded || !normalGameStore.levelStore.levelsHaveLoaded || !isSafeToDisplay ? " visible z-40" : " invisible z-[-1]")}>
                        <div>
                            <ClipLoader color="#c3c3c3" size="70" />
                        </div>
                    </div>

                }
                {

                    normalGameStore.currentLevel !== null && !normalGameStore.levelStore.isLoading && (
                        <>
                            {
                                <div className={"w-full h-full bg-black flex justify-center items-center " + (normalGameStore.isLevelCompleted ?
                                    " visible relative z-50" : " invisible absolute")}>
                                    <div className="bg-white w-[730px] h-[886px] rounded-[32px] flex flex-col items-center pt-[32px] pb-[37px]">
                                        <div className="flex mb-[40px] font-MarvinRound relative w-full justify-center items-center">
                                            <span className="text-[81px] text-[#51AABC] leading-[107px] ">BIEN JOUÉ</span>
                                            <span className="text-[122px] text-[#51AABC] leading-[163px] tracking-[0.02em] rotate-[-2deg] absolute right-[50px]">!</span>
                                        </div>
                                        <div className="flex flex-col items-center font-FredokaOne">

                                            {normalGameStore.index === normalGameStore.levelStore.levels.length - 1 ?

                                                (<button onClick={() => { normalGameStore.restartLastLevel(); playNavigation(); normalGameStore.hideLevelCompletedScreen(); }} style={{ boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.15)" }} className="rounded-[42px] border-[#fff] border-[4px]
                                             bg-[#51AABC] text-white text-[30px] pt-[17px] pb-[22px] w-[360px] h-[83px] mb-[25px]">REPRENDRE</button>) :

                                                (<button onClick={() => { normalGameStore.goToLastLevel(); normalGameStore.hideLevelCompletedScreen(); playNavigation() }} style={{ boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.15)" }}
                                                    className="rounded-[42px] border-[#fff] border-[4px]
                                             bg-[#51AABC] text-white text-[30px] pt-[17px] pb-[22px] w-[360px] h-[83px] mb-[25px]">SUIVANT</button>)
                                            }

                                            <Link to="/">
                                                <button onClick={() => {
                                                    normalGameStore.isFullScreen = false;
                                                    //normalGameStore.index === normalGameStore.levelStore.levels.length - 1 && normalGameStore.restart();
                                                    playNavigation();
                                                }} style={{ boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.15)" }} className="rounded-[42px] border-[#fff] border-[4px]
                                             text-white bg-[#51AABC] text-[30px] pt-[17px] pb-[22px] w-[360px] h-[83px] mb-[54px]">ACCUEIL</button>
                                            </Link>

                                            {normalGameStore.isLoadingNovels ?

                                                <div>
                                                    <ClipLoader color="#000" size="70" />
                                                </div>
                                                :
                                                normalGameStore.novel !== null &&

                                                <>
                                                    <a href={normalGameStore.novel.link} className="relative w-[391px] h-[365px] flex justify-center items-center cursor-pointer">

                                                        <div className="relative flex justify-center items-center">
                                                            <img className="rounded-[18px] z-0" src={normalGameStore.novel.posterVideo} alt="" />
                                                            <img className="absolute right-[10px] top-[10px] w-[96px]" src={foulanePlayVideo} alt="" />
                                                            <img className="absolute w-[63px]" src={iconSmallPlayVideo} alt="" />
                                                        </div>

                                                    </a>
                                                    <span className=" text-center text-[#223C66] text-[20px] mt-[3px] mb-[37px]">Cliquez sur l’image pour regarder <br /> {normalGameStore.novel.name}</span>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            }


                            <div className={"flex justify-center h-full w-full"}>
                                <div unselectable="on" ref={imagesContainerEl} className={"flex flex-col items-center w-full h-full px-[var(--content-margin)] justify-center " + (!normalGameStore.isFullScreen && "pt-[18px] pb-[52px]")}>

                                    <span ref={descriptionEl} className={"text-[37px] mt-[13px] mb-[24px] text-white font-Mali font-normal" +
                                        (normalGameStore.isFullScreen && " hidden")}>{normalGameStore.lastLevelAttempt.level?.description}</span>

                                    <div>
                                        {normalGameStore.isFullScreen &&

                                            <img onLoad={scaleImages} ref={zoomOutEl} src={zoomOut} className={styles.zoomOut} onClick={() => { normalGameStore.onToggleFullScreen(); playNavigation() }} alt="" />

                                        }

                                        <div unselectable="on" ref={imagesSuperWrapperEl} className={"overflow-hidden selectDisable"}>
                                            <div unselectable="on" style={{ transform: `scale(${scale})` }} className={"flex origin-top-left selectDisable"}>
                                                <div unselectable="on" className="relative mr-[24px] flex-shrink-0 overflow-hidden selectDisable">
                                                    <img unselectable="on" ref={imageEl} onLoad={scaleImages} src={(normalGameStore.lastLevelAttempt.level?.imageOriginal)} className={"rounded-[20px] " + styles.selectionDisabled} alt="" />

                                                    <div
                                                        className={"absolute top-0 left-0 w-full h-full " + styles.tapEffectDisabled}
                                                        onClick={(event) => {
                                                            playIncorrect();
                                                        }}>
                                                    </div>

                                                    {normalGameStore.lastLevelAttempt?.level.levelErrors.map((levelError: any) => {

                                                        return (<div key={uuidv4()}
                                                            onClick={() => onErrorClick(levelError)}
                                                            className={"absolute flex justify-center items-center " + styles.tapEffectDisabled}
                                                            unselectable="on"
                                                            style={{
                                                                width: levelError.width + 'px',
                                                                height: levelError.height + 'px',
                                                                left: (levelError.x - (levelError.width / 2)) + 'px',
                                                                top: (levelError.y - (levelError.height / 2)) + 'px'
                                                            }}>
                                                            {normalGameStore.lastLevelAttempt.levelErrorsFound.find((levelErrorFound: any) => levelErrorFound.levelErrorKey === levelError.levelErrorKey) && <img unselectable="on" className="absolute min-w-[100px] min-h-[100px] w-[100px] h-[100px] selectDisable" src={roundedZoneSelection} alt="" />}
                                                        </div>)
                                                    })}

                                                    {normalGameStore.lastLevelAttempt.level.levelErrors.map((levelError: any) =>
                                                        <div unselectable="on" key={uuidv4()}
                                                            className={"absolute selectDisable " + (!levelError.useHint ? "hidden" : "")}
                                                            style={{
                                                                background: 'rgba(81,170,188,0.45)',
                                                                width: levelError.width + 450 + 'px',
                                                                height: levelError.height + 450 + 'px',
                                                                left: (levelError.x - (levelError.width / 2)) + 'px',
                                                                top: (levelError.y - (levelError.height / 2)) + 'px'
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>



                                                <div unselectable="on" className="relative flex-shrink-0 overflow-hidden selectDisable">
                                                    <img unselectable="on" src={normalGameStore.lastLevelAttempt.level?.imageOriginal} className={"rounded-[20px] selectDisable " + styles.selectionDisabled} alt="" />
                                                    {normalGameStore.lastLevelAttempt.level.levelErrors.map((levelError: LevelError) => {
                                                        return (
                                                            <img key={uuidv4()} src={levelError.errorImage} className={"w-full top-0 left-0 absolute " + styles.selectionDisabled}
                                                                unselectable="on"
                                                                style={{
                                                                    clipPath: `polygon(${(levelError.x - levelError.width / 2)}px ${(levelError.y - levelError.height / 2)}px,
                                                                        ${((levelError.x - levelError.width / 2) + (levelError.width))}px ${(levelError.y - levelError.height / 2)}px,
                                                                         ${((levelError.x - levelError.width / 2) + (levelError.width))}px ${((levelError.y - levelError.height / 2) + (levelError.height))}px,
                                                                          ${(levelError.x - levelError.width / 2)}px ${((levelError.y - levelError.height / 2) + (levelError.height))}px)`
                                                                }}>
                                                            </img>
                                                        )
                                                    }
                                                    )}

                                                    <div
                                                        className={"absolute top-0 left-0 w-full h-full " + styles.tapEffectDisabled}
                                                        onClick={(event) => {
                                                            playIncorrect();
                                                        }}>
                                                    </div>

                                                    {normalGameStore.lastLevelAttempt.level.levelErrors.map((levelError: any) =>

                                                        <div key={uuidv4()} onClick={() => onErrorClick(levelError)}

                                                            className={`absolute ${styles.tapEffectDisabled} flex justify-center items-center `}

                                                            style={{
                                                                // background: '#ff000050',
                                                                width: levelError.width + 'px',
                                                                height: levelError.height + 'px',
                                                                left: (levelError.x - (levelError.width / 2)) + 'px',
                                                                top: (levelError.y - (levelError.height / 2)) + 'px'
                                                            }}>

                                                            {normalGameStore.lastLevelAttempt.levelErrorsFound.find((levelErrorFound: any) => levelErrorFound.levelErrorKey === levelError.levelErrorKey) && <img className={"absolute min-w-[100px] min-h-[100px] w-[100px] h-[100px] " + styles.selectionDisabled} src={roundedZoneSelection} alt="" />}

                                                        </div>
                                                    )}

                                                    {normalGameStore.lastLevelAttempt.level.levelErrors.map((levelError: any) =>
                                                        <div key={uuidv4()}
                                                            className={"absolute " + (!levelError.useHint ? "hidden" : "")}
                                                            style={{
                                                                background: 'rgba(81,170,188,0.45)',
                                                                width: levelError.width + 450 + 'px',
                                                                height: levelError.height + 450 + 'px',
                                                                left: (levelError.x - (levelError.width / 2)) + 'px',
                                                                top: (levelError.y - (levelError.height / 2)) + 'px'
                                                            }}>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span ref={subDescriptionEl} className={"text-[30px] mt-[31px] text-[#C6C96E] font-Mali font-normal" + (normalGameStore.isFullScreen && " hidden")} >{normalGameStore.lastLevelAttempt.level?.subDescription}</span>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    );
});

export default NormalMode;

