import styles from './Scores.module.css'
import tableauDesScores from '../../images/tableau-des-scores.svg'
import { Link } from 'react-router-dom'
import BaseDS from '../../API/BaseDS';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CalculateScorePosition from '../../Common/CalculateScorePosition';
import { ClipLoader } from 'react-spinners';
//@ts-ignore
import navigationSound from "../../assets/sounds/navigation.mp3";
import useSound from "use-sound";


const Score = ({quickGameStore, userStore} : any) => {

    const [todayScores, setTodayScores] = useState<any>(null);
    const [thisYearScores, setThisYearScores] = useState<any>(null);
    const [playNavigation] = useSound(navigationSound, { volume: 0.5 });

    const getAllScores = async () => {

        BaseDS.getAllScores().then((scores) => {

            let todayScores = scores.filter((score: any) => CalculateScorePosition.isToday(score.date) === true);
            let thisYearScores = scores.filter((score: any) => CalculateScorePosition.isThisYear(score.date) === true);

            todayScores = todayScores.sort(function(a: any, b: any){
                return b.score - a.score;
            }).slice(0, 200);

            thisYearScores = thisYearScores.sort(function(a: any, b: any){
                return b.score - a.score;
            }).slice(0, 200);

            setTodayScores(todayScores);
            setThisYearScores(thisYearScores);

        });
    }

    useEffect(() => {

        getAllScores()

    }, [])

    return (

        <div className={styles.container + ' h-full'}>

            <div className="ml-[403px] mt-[88px] flex flex-col w-min">

                <div className="w-[944px] h-[706px] bg-white pt-[17px] rounded-[20px] flex flex-col items-center">

                    <img className="mb-[17px]" src={tableauDesScores} alt="" />

                    <div className="flex flex-grow">

                        <div className="flex-grow w-[412px] h-[583px] rounded-[20px] bg-[#4C7A79] flex flex-col items-center mr-[30px] pt-[18px]">

                            <span className="text-[31px] text-[#EAC676] font-FredokaOne">CHAMPIONS DU JOUR</span>

                            {todayScores ?

                                <div style={{fontFamily: "CourierBOLD, sans-serif"}}
                                     className="mt-[6px] font-bold flex text-[29px] text-white justify-around px-[37px] w-full text-center overflow-y-auto">

                                    <div className="flex flex-col">
                                        <span>RANG</span>

                                        {todayScores.map((score: any, index: number = 0) => {
                                            return <span className={userStore.user?.userId === score.scoreId ? "text-[#FCC566]" : ""} key={score.scoreKey}>{index + 1}</span>
                                        })
                                        }

                                    </div>

                                    <div className="flex flex-col">
                                        <span>NOM</span>

                                        {todayScores.map((score: any, index: number = 0) => {
                                            return <span className={userStore.user?.userId === score.scoreId ? "text-[#FCC566]" : ""} key={score.scoreKey}>{score.username}</span>
                                        })
                                        }

                                    </div>

                                    <div className="flex flex-col">
                                        <span>SCORE</span>

                                        {todayScores.map((score: any, index: number = 0) => {
                                            return <span className={userStore.user?.userId === score.scoreId ? "text-[#FCC566]" : ""} key={score.scoreKey}>{score.score}</span>
                                        })
                                        }

                                    </div>
                                </div>



                                : <div className={ "text-center m-auto "}>
                                    <ClipLoader color="#c3c3c3" size="70" />
                                </div>

                            }


                        </div>

                        <div className="flex-grow w-[412px] h-[583px] rounded-[20px] bg-[#4C7A79] flex flex-col items-center pt-[18px]">

                            <span className="text-[31px] text-[#EAC676] font-FredokaOne">CHAMPIONS {new Date().getFullYear()}</span>

                            {thisYearScores ?

                                <div style={{fontFamily: "CourierBOLD, sans-serif"}}
                                     className="mt-[6px] font-bold flex text-[29px] text-white justify-around px-[37px] w-full text-center overflow-y-auto">

                                    <div className="flex flex-col">
                                        <span>RANG</span>

                                        {thisYearScores.map((score: any, index: number = 0) => {
                                            return <span className={userStore.user?.userId === score.scoreId ? "text-[#FCC566]" : ""} key={score.scoreKey}>{index + 1}</span>
                                        })
                                        }

                                    </div>

                                    <div className="flex flex-col">
                                        <span>NOM</span>

                                        {thisYearScores.map((score: any, index: number = 0) => {
                                            return <span className={userStore.user?.userId === score.scoreId ? "text-[#FCC566]" : ""} key={score.scoreKey}>{score.username}</span>
                                        })
                                        }

                                    </div>

                                    <div className="flex flex-col">
                                        <span>SCORE</span>

                                        {thisYearScores.map((score: any, index: number = 0) => {
                                            return <span className={userStore.user?.userId === score.scoreId ? "text-[#FCC566]" : ""} key={score.scoreKey}>{score.score}</span>
                                        })
                                        }

                                    </div>
                                </div>

                                : <div className={ "text-center m-auto "}>
                                    <ClipLoader color="#c3c3c3" size="70" />
                                </div>

                            }

                        </div>
                    </div>

                </div>

                <Link onClick={() => playNavigation()} to={quickGameStore.isGameFinished === true ? "/mode/rapide" : "/"} className="mt-[25px] mx-auto">

                    <button className="w-[670px] h-[88px] text-[37px] bg-[#4C7A79] text-white rounded-[21px] font-MarvinRound">RETOUR</button>

                </Link>

            </div>

        </div>

    )}

export default Score;