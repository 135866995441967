import Level from "../Entity/Level";
import {
    makeAutoObservable
} from "mobx";
import LevelAttempt from "../Entity/LevelAttempt";
import LevelStore from "./LevelStore";
import moment, { Moment } from "moment";
import LevelError from "../Entity/LevelError";
import loadImage from "../Common/loadImage";
import BaseDS from "../API/BaseDS";
import CalculateScorePosition from "../Common/CalculateScorePosition";
//@ts-ignore
import wonSound from "../assets/sounds/won.mp3";

class QuickGameStore {

    initialTimeLeft = moment(new Date('October 21, 2032 00:01:30'));
    chrono: number = 0;
    timeRemaining: Moment = this.initialTimeLeft;
    stringifyTimeRemaining = "01:30"
    index: number = -2;
    isPause: boolean = false;
    levelStore: LevelStore;
    levelAttempts: Array <LevelAttempt> = [];
    isGameFinished: boolean = false;
    isFullScreen: boolean = false;
    score: number = 0;
    scorePosition = 0;
    userScorePositionCalculated = false;
    isScoreSent = false;
    mistake: object|null = null;
    mistake1: object|null = null;
    lastLevelSkipDate: number|null = null;

    constructor(levelStore: LevelStore) {
        makeAutoObservable(this);
        this.levelStore = levelStore;
    }

    initializeGame() {

        if (!this.levelStore.levelsHaveLoaded) return;

        this.reset();
        this.nextAvailableLevel();
    }

    startGame() {
        this.updateChrono();
        this.index = 0;
    }

    reset(){
        this.isFullScreen = false;
        this.isScoreSent = false;
        this.timeRemaining = this.initialTimeLeft;
        this.levelAttempts = [];
        this.index = -2;
        this.score = 0;
        this.userScorePositionCalculated = false;
        this.isGameFinished = false;
        this.stringifyTimeRemaining = "01:30";
        clearInterval(this.chrono);
        this.isPause = false;
        this.mistake = null;
        this.mistake1 = null;
    }

    restart() {
        this.initializeGame();
    }

    updateChrono() {

        this.chrono = window.setInterval(() => {

            if (this.isPause !== true) {
                this.timeRemaining = moment(this.timeRemaining).subtract(1, "second");
                this.stringifyTimeRemaining = this.timeRemaining.format('mm:ss');
                this.stopAtZero();
            }

        }, 1000);
    }

    stopAtZero() {

        if (this.stringifyTimeRemaining === "00:00") {
            clearInterval(this.chrono);
            this.isGameFinished = true;
            this.calculateUserScorePosition();
        }
    }

    nextAvailableLevel() {
        this.mistake = null;
        this.mistake1 = null;
        if(this.index > this.levelAttempts.length - 5){
            for(let i = 0; i < 10; i++){
                this.nextLevel();
            }
        }
        if(this.index !== this.levelAttempts.length - 1){
            this.index = this.index + 1;
        }else {
            let levelsCompleted = 0;

            this.levelAttempts.forEach((levelAttempt) => {

                if (levelAttempt.levelErrorsFound.length === 1) {
                    levelsCompleted++;
                }
            });

            if (levelsCompleted === this.levelStore.levelsQuickGame.length) {

                clearInterval(this.chrono);
                this.isGameFinished = true;
                this.calculateUserScorePosition();

            }
        }
    }


    calculateUserScorePosition = async () => {
        const userScore = this.score;

        (new Audio(wonSound)).play()

        BaseDS.getAllScores().then((scores) => {

            let todayScores = scores.filter((score: any) => CalculateScorePosition.isToday(score.date) === true);
            let thisYearScores = scores.filter((score: any) => CalculateScorePosition.isThisYear(score.date) === true);

            todayScores = todayScores.sort(function(a: any, b: any){
                return b.score - a.score;
            });

            thisYearScores = thisYearScores.sort(function(a: any, b: any){
                return b.score - a.score;
            });

            if (thisYearScores.length !== 0) {
                if (thisYearScores[thisYearScores.length -1].score > userScore) {
                    this.scorePosition = thisYearScores.length +1;
                    this.userScorePositionCalculated = true;
                    return;
                }
                else
                {
                    thisYearScores.forEach((score: any, index: number) => {

                        if(score.score <= userScore && !this.userScorePositionCalculated)
                        {
                            this.scorePosition = index + 1;
                            this.userScorePositionCalculated = true;
                            return;
                        }
                    });
                }
            }
            else
            {
                this.scorePosition = 1;
                this.userScorePositionCalculated = true;
                return;
            }
        });
    };

    nextLevel() {

        let randomLevel: Level;
        let randomIterationLimit = 350;
        let randomIteration = 0;

        do {
            randomLevel = this.levelStore.getRandomLevelForQuickGame(this.levelAttempts);
            randomIteration++;

        } while (
            this.levelAttempts.find((levelAttempt) => levelAttempt.level === randomLevel)
            && this.levelAttempts.length < this.levelStore.levelsQuickGame.length
            && randomIteration < randomIterationLimit
            && (this.levelAttempts.length > 0 ? this.currentLevel?.imageOriginal === randomLevel.imageOriginal : false )
            );

        if (this.levelAttempts.length !== this.levelStore.levelsQuickGame.length) {

            let levelAttempt = new LevelAttempt(randomLevel);
            this.levelAttempts.push(levelAttempt);

            Promise.all([
                loadImage(levelAttempt.level.imageOriginal),
                ...levelAttempt.level.levelErrors.map((error: LevelError) => {
                    return loadImage(error.errorImage);
                })
            ])
                .then(() => {
                    levelAttempt.allImagesAreLoaded = true;
                })
                .catch(err => console.log("Failed to load images", err))

        }
    };

    get isReadyToStart(){
        return this.levelAttempts.length >= 2 && this.levelAttempts[0].allImagesAreLoaded && this.levelAttempts[1].allImagesAreLoaded;
    }

    pause() {
        this.isPause = true;
    }

    previousLevel() {
        if(this.index > 0)
        {
            this.index = this.index - 1;
        }
    }

    onMistake(event: any) {
        this.mistake = {x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY};
        setTimeout(() => this.nextAvailableLevel(),1000);
    }

    get shouldDisplayMistake() {
        return this.mistake != null;
    }

    onMistake1(event: any) {
        this.mistake1 = {x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY};
    }

    get shouldDisplayMistake1() {
        return this.mistake1 != null;
    }

    onToggleFullScreen() {
        this.isFullScreen = !(this.isFullScreen);
    }

    addBonus() {
        if (!this.isGameFinished) {
            this.score += 456 * parseFloat(this.timeRemaining.format('mm.ss'));
        }
    }

    addMalus(number: number) {
        if (!this.isGameFinished && this.mistake === null) {
            console.info('malus added')
            this.score -= number * 159
        }
    }

    checkDeserveMalus() {
        return this.index === (this.levelAttempts.length - 1);
    }

    addMalusIfDeserved(){
        if (this.checkDeserveMalus()) {
            this.addMalus(1);
        }
    }

    addInitialLevelAttempt(){
        this.levelAttempts.push(new LevelAttempt(this.levelStore.randomLevel));
    }

    levelSkipped(){
        this.lastLevelSkipDate = Date.now();
    }

    get canSkipLevel(){
        return !this.lastLevelSkipDate || Date.now() - this.lastLevelSkipDate > 1000;
    }

    get currentLevelAttempt() {
        return this.levelAttempts[this.index] || null;
    }

    get currentLevel() {
        return this.currentLevelAttempt?.level || null;
    }

    get canMakeMistake(){
        return this.mistake === null && this.currentLevelAttempt.levelErrorsFound.length !== this.currentLevelAttempt.level.levelErrors.length;
    }
}

export default QuickGameStore;