import './HooPowAppMenuV3.scss'
import React, { useContext } from 'react';
import { MainContext } from 'Components/utils/MainContext';
import ImageDataStore from 'DataStore/ImageDataStore';

export const routeToPlatformHomePage = () => {
  const protocol = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? 'https' : 'http';
  const port = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? '' : ':3001';
  return protocol + '://' + process.env.REACT_APP_DOMAIN_NAME + port
}

function HooPowAppMenuV3() {
  const { applications } = useContext(MainContext);

  return (
    <div className="hp-menu-icon-v3">
      <a href={routeToPlatformHomePage()} className="hp-menu-icon-v3--link" >
        <span>
          <object type='image/svg+xml' data={routeToPlatformHomePage() + '/icon.svg'} aria-label='HooPow' />
        </span>
      </a>
      {Array(8).fill(1).map((appDef: any, index: number) => (<React.Fragment key={index}>
        {applications && applications[index]
          && parseInt(applications[index].publicationDate.replace(/-/g, '')) <= parseInt(new Date().toISOString().replace(/-/g, '')) ? (
            <a href={applications[index].url} className="hp-menu-icon-v3--link" key={'icon_' + index}>
              <span>
                <object type='image/svg+xml' data={ImageDataStore.getUrlFileFromHoopow(applications[index].imageHomepage)} aria-label={applications[index].name} />
              </span>
            </a>
          ) : (
            <div className='hp-menu-icon-v3--empty'></div>
          )}
      </React.Fragment>))}
    </div>
  );
}

export default HooPowAppMenuV3;
