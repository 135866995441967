import {makeAutoObservable} from "mobx";
import LevelError from "./LevelError";
import Level from "./Level";

class LevelAttempt {

    level: Level;
    levelErrorsFound: Array<LevelError>;
    hintCount: number = 0;
    allImagesAreLoaded = false;

    constructor(level: Level, levelErrorsFound: Array<LevelError> = [], hintCount: number = 0) {

        makeAutoObservable(this);
        this.level = level;
        this.levelErrorsFound = levelErrorsFound;
        this.hintCount = hintCount;
    }

    get errors(): Array<any>{
        return this.level.levelErrors.map((error) => {
            return {
                levelError: error,
                isFound: this.levelErrorsFound.includes(error)              
            };
        });
    }

    addFoundLevelError(levelError: LevelError){
        this.levelErrorsFound.push(levelError);
    }

    static hydrate(levelAttempt: any){
        return new LevelAttempt(
            Level.hydrate(levelAttempt.level), 
            levelAttempt.levelErrorsFound.map((levelError: any) => LevelError.hydrate(levelError)), 
            levelAttempt.hintCount
        );
    }
}

export default LevelAttempt;
