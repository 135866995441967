import { Auth } from "aws-amplify";
import { observer } from "mobx-react";
import { useLayoutEffect, useState } from "react";
import BaseDS from "../../API/BaseDS";
import CommonStore from "../../DataStore/CommonStore";
import LevelStore from "../../DataStore/LevelStore";
import NormalGameStore from "../../DataStore/NormalGameStore";
import QuickGameStore from "../../DataStore/QuickGameStore";
import UserStore from "../../DataStore/UserStore";
import App from "./App";


const userStore = new UserStore();
const levelStore = new LevelStore();
const normalGameStore = new NormalGameStore(levelStore, userStore);
const quickGameStore = new QuickGameStore(levelStore);
const commonStore = new CommonStore();

function getCurrentUser() {
    return Auth.currentAuthenticatedUser().catch(e => {
        Auth.signOut();
    });
}

const AppInitializer = observer(() => {
    const [user, setUser] = useState<any>(null);

    useLayoutEffect(() => {
        getCurrentUser().then((result) => {
            if (result !== undefined) {
                BaseDS.getRootProfil().then((userResponse) => {
                    setUser(userResponse);
                    userStore.logUser(user);
                })
            }
        });
    }, [])

    return <App normalGameStore={normalGameStore} levelStore={levelStore} quickGameStore={quickGameStore} userStore={userStore} commonStore={commonStore} />

});


export default AppInitializer;
