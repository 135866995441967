import styles from './QuickMode.module.css';
import LevelError from "../../Entity/LevelError";
import { observer } from "mobx-react";
import roundedArrow from "../../images/rounded-arrow.svg"
import chercheLerreur from "../../images/cherche-lerreur.svg"
import arrowLeft from "../../images/arrow-left-quick-game.svg"
import arrowRight from "../../images/arrow-right-quick-game.svg"
import pauseIcon from "../../images/pause-icon.svg"
import pauseIconBlue from "../../images/pause-icon-blue.svg"
import sendScoreButton from "../../images/send-score-button.svg"
import zoomOut from '../../images/zoom-out.svg';
import trophy from "../../images/trophy.svg"
import { Link } from 'react-router-dom';
import { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';
import goButton from "../../images/go-button.svg"
import errorIcon from "../../images/error-icon.svg"
import greenChecked from '../../images/good-icon.svg';
import BaseDS from '../../API/BaseDS';
import { v4 as uuidv4 } from 'uuid';
import LevelAttempt from '../../Entity/LevelAttempt';
import { reaction } from 'mobx';
import { ClipLoader } from 'react-spinners';
import useSound from "use-sound";
//@ts-ignore
import correctSound from "../../assets/sounds/correct.mp3";
//@ts-ignore
import incorrectSound from "../../assets/sounds/incorrect.mp3";
//@ts-ignore
import wonSound from "../../assets/sounds/won.mp3";
//@ts-ignore
import lostSound from "../../assets/sounds/lost.mp3";
//@ts-ignore
import navigationSound from "../../assets/sounds/navigation.mp3";

export const QuickMode = observer(({ quickGameStore, userStore }: any) => {

    const [playCorrect] = useSound(correctSound, { volume: 0.3 });
    const [playIncorrect] = useSound(incorrectSound, { volume: 1.3 });
    const [playWon] = useSound(wonSound, { volume: 0.1 });
    const [playLost] = useSound(lostSound, { volume: 0.3 });
    const [playNavigation] = useSound(navigationSound, { volume: 0.3 });
    const [username, setUsername] = useState('INVIT');
    const [scale, setScale] = useState(1);
    const [isSendingScore, setIsSendingScore] = useState(false);

    let imagesContainerEl = useRef(null);
    let imageEl = useRef(null);
    let imagesWrapperEl = useRef(null);
    let zoomOutEl = useRef(null);
    let imageTitleEl = useRef(null);
    let arrowsEl = useRef(null);
    let imagesSuperWrapperEl = useRef(null);
    let mainErrorImg = useRef(null);
    let errorImages = useRef(null);
    let hidderEl = useRef(null);
    let mainDiv = useRef(null);

    const wrapperStyle: CSSProperties = {
        visibility: 'visible',
    }

    const currentLevelIndex = quickGameStore.index;

    // @ts-ignore
    let imagesContainerElStyle: CSSStyleDeclaration = imagesContainerEl.current && getComputedStyle(imagesContainerEl.current);

    let imagesContainerSmallWidth: number;

    // @ts-ignore
    const scaleImages = () => {

        // @ts-ignore
        let imagesSuperWrapperElStyle: CSSStyleDeclaration = imagesSuperWrapperEl.current && getComputedStyle(imagesSuperWrapperEl.current);

        // @ts-ignore
        const imageEl = imagesContainerEl.current.querySelector('img[data-imageel]');

        if(!imageEl){
            return;
        }

        // @ts-ignore
        let imageElStyle: CSSStyleDeclaration = imageEl && getComputedStyle(imageEl);

        // @ts-ignore
        let zoomOutElStyle: CSSStyleDeclaration = zoomOutEl.current && getComputedStyle(zoomOutEl.current);

        // @ts-ignore
        let imageTitleElStyle: CSSStyleDeclaration = imageTitleEl.current && getComputedStyle(imageTitleEl.current);

        // @ts-ignore
        let arrowsElStyle: CSSStyleDeclaration = arrowsEl.current && getComputedStyle(arrowsEl.current);

        let imagesContainerSmallheight;

        if(imagesSuperWrapperEl.current != null )
        {
            // @ts-ignore
            imagesSuperWrapperEl.current.style.width = 'unset';
            // @ts-ignore
            imagesSuperWrapperEl.current.style.height = 'unset';

            if(quickGameStore.isFullScreen){

                imagesContainerSmallWidth = parseFloat(imagesContainerElStyle?.width) -
                    (parseFloat(imagesContainerElStyle?.paddingRight) + parseFloat(imagesContainerElStyle?.paddingLeft)) +  (parseFloat(zoomOutElStyle?.left))

                imagesContainerSmallheight = parseFloat(imagesContainerElStyle?.height) - parseFloat(imagesContainerElStyle?.paddingTop) -
                    parseFloat(imagesContainerElStyle?.paddingBottom) - parseFloat(arrowsElStyle?.height) - 50;

            } else {

                imagesContainerSmallWidth = parseFloat(imagesContainerElStyle?.width) - (parseFloat(imagesContainerElStyle?.paddingRight) + parseFloat(imagesContainerElStyle?.paddingLeft));
                imagesContainerSmallheight = parseFloat(imagesContainerElStyle?.height)
                    - parseFloat(imagesContainerElStyle?.paddingTop) -
                    parseFloat(imagesContainerElStyle?.paddingBottom) - parseFloat(imageTitleElStyle?.height)- parseFloat(arrowsElStyle?.height) - 50;

            }

            let imagesContainerBigWidth = parseFloat(imageElStyle?.width) * 2 + 24 /*parseFloat(imageElStyle?.marginRight)*/;
            let imagesContainerBigHeight = parseFloat(imageElStyle?.height);
            let imagesContainerHeightRatio = imagesContainerSmallheight / imagesContainerBigHeight;
            let imagesContainerWidthRatio = imagesContainerSmallWidth / imagesContainerBigWidth;
            let computedScale = Math.min(1, Math.min(imagesContainerWidthRatio,  imagesContainerHeightRatio));

            // @ts-ignore
            imagesSuperWrapperEl.current.style.width = (imagesContainerBigWidth * computedScale) + 'px';
            // @ts-ignore
            imagesSuperWrapperEl.current.style.height = (imagesContainerBigHeight * computedScale) + 'px';

            setScale(computedScale);
        }
    }


    useLayoutEffect(scaleImages, [imagesContainerEl, imagesWrapperEl, imagesSuperWrapperEl, zoomOutEl, quickGameStore.isFullScreen, quickGameStore.index]);


    const onErrorClick = (levelError : LevelError) => {

        if(!quickGameStore.currentLevelAttempt.levelErrorsFound.includes(levelError) && quickGameStore.mistake === null){

            quickGameStore.currentLevelAttempt.addFoundLevelError(levelError);
            quickGameStore.addBonus();
            playCorrect();
            quickGameStore.mistake = null;
            setTimeout(() => quickGameStore.nextAvailableLevel(), 500);
        }
    };

    const sendScoreToDB = () => {
        setIsSendingScore(true);
        BaseDS.sendScore({
            username: username != "" ? username.toUpperCase() : "INVIT",
            score: Math.round(quickGameStore.score) < 0 ? 0 : Math.round(quickGameStore.score),
            date: new Date()
        }).then((res) => {
            setIsSendingScore(false)
            quickGameStore.isScoreSent = true;
        });
    }

    useEffect(() => {
        reaction(() => quickGameStore.levelStore.levelsHaveLoaded, () => {
            if (!quickGameStore.isGameFinished) {
                quickGameStore.initializeGame()
            }
        }, {
            fireImmediately: true
        })
    }, [])

    return (
        <div ref={mainDiv} className="h-full">
            <div className={"relative h-full flex-grow rounded-[16px] overflow-hidden " + (quickGameStore.isFullScreen ? "bg-black" : "bg-white")}>
                {
                    !quickGameStore.isReadyToStart &&

                    <div className={"text-center m-auto z-[50] rounded-[16px] bg-white flex justify-center items-center w-full h-full"}>
                        <ClipLoader color="#c3c3c3" size="70" />
                    </div>
                }

                {
                    <>
                        <div className={"w-full h-full bg-black flex justify-center items-center " + (quickGameStore.isGameFinished ? " visible absolute z-50" : " invisible absolute")}>
                            <div className="bg-white w-[730px] h-[886px] rounded-[72px] font-FredokaOne flex flex-col items-center pt-[30px] pb-[47px]">
                                <div className="flex mb-[11px]">
                                    <span className="text-[96px] text-[#51AABC] leading-[128px] mr-[43px] ml-[76px]">RÉSULTAT</span>
                                    <span className="text-[122px] text-[#51AABC] leading-[163px] mt-[-18px] rotate-[10deg]">!</span>
                                </div>

                                <div className="flex mt-[11px]">
                                    <div style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}} className="w-[304px] h-[164px] rounded-[30px] bg-white mr-[35px] flex flex-col justify-center items-center">
                                        <span className="text-[30px]">VOTRE SCORE</span>
                                        <span className="text-[71px]">{Math.round(quickGameStore.score) < 0 ? 0 : Math.round(quickGameStore.score)}</span>
                                    </div>
                                    <div style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}} className="w-[304px] h-[164px] rounded-[30px] bg-white flex flex-col justify-center items-center">
                                        <span className="text-[30px] mb-auto">VOTRE POSITION</span>
                                        {!quickGameStore.userScorePositionCalculated ?
                                            <div className="mb-auto"><ClipLoader size="70" /></div> :
                                            <span className="text-[71px]">{quickGameStore.userScorePositionCalculated ? quickGameStore.scorePosition : "..."}</span>
                                        }

                                    </div>
                                </div>

                                <div className="m-auto text-center">
                                    {
                                        (isSendingScore && !quickGameStore.isScoreSent) &&
                                        <ClipLoader size="70" />
                                    }

                                    {
                                        (quickGameStore.isScoreSent) &&
                                        <p className="text-[66px]">Score Enregistré !</p>
                                    }

                                    <div className={(isSendingScore && !quickGameStore.isScoreSent) || quickGameStore.isScoreSent ? " hidden" : "" }>
                                        <span className="text-[38px]">INSCRIVEZ VOTRE NOM</span>
                                        <div className="flex items-center h-[164px] mt-[11px] mr-[76px] relative">
                                            <img className={"scale-x-[-1] w-[46px] mx-[25px]"} src={arrowLeft} alt="" />
                                            <input maxLength={5} onChange={(event) => setUsername(event.target.value)} className="text-[89px] text-[#DDDDDD uppercase w-[548px] h-full rounded-[30px] border-[5px] border-[#DDDDDD] placeholder-[#DDDDDD] flex text-center" placeholder="AAAAA" required></input>
                                            <img onClick={() => {sendScoreToDB(); playNavigation()}} className={"cursor-pointer w-[79px] absolute right-[-36px]"} src={sendScoreButton} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex ">
                                        <button onClick={() => {quickGameStore.restart(); playNavigation()}} style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}} className="rounded-[42px] border-[#fff] border-[4px]
                                             bg-[#51AABC] text-white text-[30px] pt-[17px] pb-[22px] w-[269px] h-[83px] mr-[10px]">REJOUER</button>

                                        <Link to={`/`}>
                                            <button onClick={() => {
                                                quickGameStore.reset();
                                                playNavigation()
                                            }}
                                                    style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}}
                                                    className="rounded-[42px] border-[#fff] border-[4px]
                                                 bg-[#51AABC] text-white text-[30px] pt-[17px] pb-[22px] w-[269px] h-[83px]">ACCUEIL</button>
                                        </Link>
                                    </div>

                                    <Link to="/scores" onClick={() => {
                                        quickGameStore.isFullScreen = false;
                                        playNavigation()
                                    }}
                                          className="mt-[34px] flex items-center relative cursor-pointer">

                                        <img className="absolute" src={trophy} alt="" />
                                        <button className="w-[548px] h-[83px] rounded-[42px] bg-[#8B563A] text-white text-[34px]">TABLEAU DES SCORES</button>
                                    </Link>
                                </div>

                            </div>
                        </div>


                        <div className={"bg-[#adb13ff1] w-full h-full z-20 rounded-[16px] top-0 left-0 " + ((quickGameStore.index < 0 && quickGameStore.isReadyToStart) ? " visible absolute" : " invisible absolute" )}>
                            <div className="flex flex-col items-center text-white pt-[168px] z-10 absolute top-0 left-0 w-full h-full">
                                <div className="relative h-[641px] flex">
                                    <div className="flex flex-col absolute font-MarvinRound">
                                        <span className="text-[62px] leading-[83px] rotate-[-5deg] tracking-wide">Clique-ici</span>
                                        <span className="text-[50px] leading-[67px] rotate-[-5deg] tracking-wide">Pour lancer</span>
                                        <span className="text-[50px] leading-[67px] ml-[55px] rotate-[-5deg] tracking-wide">La partie</span>
                                        <img className="w-[177px] h-[177px] ml-[160px]" src={roundedArrow} alt="" />
                                    </div>
                                    <div className="flex flex-col mt-auto text-center items-center">
                                        {
                                            quickGameStore.isReadyToStart ? (
                                                <img src={goButton} className="w-[237px] mb-[41px] cursor-pointer"
                                                     onClick={() => {
                                                         quickGameStore.startGame();
                                                         playNavigation();
                                                     }}
                                                />
                                            ) : (<p>Chargement du jeu...</p>)
                                        }

                                        <p className="flex flex-col text-[37px] leading-[37px] font-Mali font-medium">
                                            <span>Trouve le maximum de différences en 1 minute 30,</span>
                                            <span>mais attention :</span>
                                            <span>- Une erreur = 3 malus points</span>
                                            <span>- Passer à l’image suivante = 1 malus point</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"w-full h-full bg-black flex justify-center items-center " + (quickGameStore.isPause === true ? " visible absolute z-50" : " invisible absolute" )}>
                            <div className="bg-white w-[730px] h-[886px] rounded-[72px] flex flex-col items-center pt-[79px] pb-[70px]">
                                <img src={pauseIconBlue} className="w-[245px] " alt="" />
                                <span className="text-[96px] text-[#51AABC] leading-[128px] font-MarvinRound ">EN PAUSE</span>

                                <div className="flex flex-col mt-[64px] font-FredokaOne">

                                    <button onClick={() => {quickGameStore.isPause = false; playNavigation()}} style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}} className="rounded-[42px] border-[#fff] border-[4px]
                                      bg-[#51AABC] text-white text-[30px] pt-[17px] pb-[22px] w-[360px] h-[83px]">REPRENDRE</button>

                                    <button onClick={() => {quickGameStore.restart(); playNavigation()}} style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}} className="rounded-[42px] border-[#fff] border-[4px]
                                         text-[#51AABC] text-[30px] pt-[17px] pb-[22px] w-[360px] h-[83px] mt-[23px]">RECOMMENCER</button>

                                    <Link to={`/`}>
                                        <button onClick={() => {
                                            quickGameStore.restart();
                                            quickGameStore.isFullScreen = false;
                                            playNavigation();
                                        }}
                                                style={{boxShadow:"0px 3px 6px 0px rgba(0,0,0,0.15)"}}
                                                className="rounded-[42px] border-[#fff] border-[4px] text-[#51AABC] text-[30px]
                                            pt-[17px] pb-[22px] w-[360px] h-[83px] mt-[23px]">QUITTER</button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div draggable="false" ref={imagesContainerEl} className={"absolute top-0 left-0 w-full h-full flex flex-col items-center justify-evenly " +
                        (!quickGameStore.isFullScreen && " px-[19px] ") + (quickGameStore.isReadyToStart ? " z-[10] " : " z-[-1] ") }>

                            <img ref={imageTitleEl} src={chercheLerreur} className={"w-[412px] " + (quickGameStore.isFullScreen && "hidden")} alt="" />
                            <div draggable="false" className={"relative"} style={wrapperStyle} ref={hidderEl}>

                                {quickGameStore.isFullScreen &&
                                <img onLoad={scaleImages} ref={zoomOutEl} src={zoomOut} className={ styles.zoomOut}
                                     onClick={() => {quickGameStore.onToggleFullScreen(); playNavigation()}} alt=""/>
                                }
                                <div draggable="false" ref={imagesSuperWrapperEl} className={"overflow-hidden  "}>

                                    {quickGameStore.levelAttempts.map((levelAttempt: LevelAttempt, i: number) => {

                                        const isCurrentLevel = quickGameStore.index === quickGameStore.levelAttempts.indexOf(levelAttempt);
                                        return (<div key={uuidv4()} draggable="false" style={{transform:`scale(${scale})`, visibility: isCurrentLevel ? 'visible' : 'visible', position: isCurrentLevel  ? 'relative' : 'absolute', zIndex: (isCurrentLevel || (quickGameStore.index === -1 && i=== 0)) ? 1 : -1 }} className={"flex origin-top-left "}>


                                            <div draggable="false" className={"relative mr-[24px] flex-shrink-0 overflow-hidden "}>
                                                <img src={errorIcon} draggable="false" style={{top: (quickGameStore.mistake?.y - 50) + "px", left: (quickGameStore.mistake?.x - 50) + "px", zIndex: 100}} className={"w-[100px] h-[100px] absolute overflow-hidden " + (quickGameStore.shouldDisplayMistake ? "visible" : "hidden") } />

                                                <img
                                                    draggable="false"
                                                    data-imageel ref={imageEl} data-loaded={"false-" + currentLevelIndex} onLoad={(e) => {scaleImages()}} src={levelAttempt.level.imageOriginal}
                                                    className={"rounded-[20px] block  " + styles.selectDisable} alt="" />

                                                <div
                                                    className={"absolute top-0 left-0 w-full h-full " + styles.tapEffectDisabled}
                                                    onClick={(event) => {
                                                        if(quickGameStore.canMakeMistake){
                                                            quickGameStore.addMalus(3);
                                                            playIncorrect();
                                                            quickGameStore.onMistake(event);
                                                        }
                                                    }}>
                                                </div>

                                                {levelAttempt.errors.map((attemptLevelError: any, i: number) =>
                                                    <div key={uuidv4()}
                                                         onClick={() =>onErrorClick(attemptLevelError.levelError)} className={`absolute flex justify-center items-center ` + styles.tapEffectDisabled}
                                                         draggable="false"
                                                         style={{
                                                             width: attemptLevelError.levelError.width + 'px',
                                                             height: attemptLevelError.levelError.height + 'px',
                                                             left: (attemptLevelError.levelError.x - (attemptLevelError.levelError.width / 2)) + 'px',
                                                             top: (attemptLevelError.levelError.y - (attemptLevelError.levelError.height / 2)) + 'px',
                                                         }}>

                                                        {attemptLevelError.isFound && <img draggable="false" className={" absolute min-w-[100px] min-h-[100px] w-[100px] h-[100px]  " + styles.selectDisable} src={greenChecked} alt="" />}

                                                    </div>
                                                )}
                                            </div>


                                            <div draggable="false" className={"relative flex-shrink-0 overflow-hidden "}>
                                                <img src={errorIcon} draggable="false" style={{top: (quickGameStore.mistake?.y - 50) + "px", left: (quickGameStore.mistake?.x - 50) + "px", zIndex: 100}} className={"w-[100px] h-[100px] absolute overflow-hidden " + (quickGameStore.shouldDisplayMistake ? "visible" : "hidden") } />

                                                <img
                                                    ref={mainErrorImg}
                                                    draggable="false"
                                                    src={levelAttempt.level.imageOriginal}
                                                    className={"rounded-[20px] block  " + styles.selectDisable}
                                                    alt="" />
                                                <div  ref={errorImages} draggable="false" className={styles.tapEffectDisabled}>
                                                    {levelAttempt.errors.map((attemptLevelError: any, i: number) =>

                                                        <img key={uuidv4()} src={attemptLevelError.levelError.errorImage} className={"w-full top-0 left-0 absolute  " + styles.selectDisable + " " + styles.tapEffectDisabled }
                                                             draggable="false"
                                                             style={{
                                                                 clipPath: `polygon(${(attemptLevelError.levelError.x - attemptLevelError.levelError.width / 2)}px ${(attemptLevelError.levelError.y - attemptLevelError.levelError.height / 2)}px,
                                                            ${((attemptLevelError.levelError.x - attemptLevelError.levelError.width / 2) + (attemptLevelError.levelError.width))}px ${(attemptLevelError.levelError.y - attemptLevelError.levelError.height / 2)}px,
                                                             ${((attemptLevelError.levelError.x - attemptLevelError.levelError.width / 2) + (attemptLevelError.levelError.width))}px ${((attemptLevelError.levelError.y - attemptLevelError.levelError.height / 2) + (attemptLevelError.levelError.height))}px,
                                                              ${(attemptLevelError.levelError.x - attemptLevelError.levelError.width / 2)}px ${((attemptLevelError.levelError.y- attemptLevelError.levelError.height / 2) + (attemptLevelError.levelError.height) )}px)`
                                                             }} >
                                                        </img>

                                                    )}
                                                    <div
                                                        className={"absolute top-0 left-0 w-full h-full " + styles.tapEffectDisabled}
                                                        onClick={(event) => {
                                                            if(quickGameStore.canMakeMistake){
                                                                quickGameStore.addMalus(3);
                                                                playIncorrect();
                                                                quickGameStore.onMistake(event);
                                                            }
                                                        }}>
                                                    </div>
                                                </div>

                                                {levelAttempt.errors.map((attemptLevelError: any, i: number) =>
                                                    <div key={uuidv4()}
                                                         onClick={() =>onErrorClick(attemptLevelError.levelError)}

                                                         className={`absolute flex justify-center items-center ` + styles.tapEffectDisabled}
                                                         draggable="false"

                                                         style={{
                                                             width: attemptLevelError.levelError.width + 'px',
                                                             height: attemptLevelError.levelError.height + 'px',
                                                             left: (attemptLevelError.levelError.x - (attemptLevelError.levelError.width / 2)) + 'px',
                                                             top: (attemptLevelError.levelError.y - (attemptLevelError.levelError.height / 2)) + 'px',
                                                         }}>

                                                        {attemptLevelError.isFound && <img draggable="false" className={"absolute min-w-[100px] min-h-[100px] w-[100px] h-[100px] " + styles.selectDisable} src={greenChecked} alt="" />}

                                                    </div>
                                                )}
                                            </div>
                                        </div>)
                                    })}

                                </div>
                            </div>

                            <div  ref={arrowsEl} className="flex">
                                <img onLoad={() => scaleImages()} onClick={() => {quickGameStore.previousLevel(); playNavigation()}} className={"cursor-pointer " + (quickGameStore.index > 0 && " scale-x-[-1]")}
                                     src={quickGameStore.index > 0 ? arrowRight : arrowLeft  } alt="" />
                                <img onLoad={() => scaleImages()} onClick={() => {quickGameStore.pause(); playNavigation()}} className="cursor-pointer mx-[26px]" src={pauseIcon} alt="" />
                                <img onLoad={() => scaleImages()} onClick={() => {
                                    if(!quickGameStore.canSkipLevel) return;

                                    quickGameStore.nextAvailableLevel();
                                    quickGameStore.levelSkipped();
                                    if(quickGameStore.checkDeserveMalus()) playIncorrect()
                                    quickGameStore.addMalusIfDeserved();
                                    playNavigation();
                                }} className={"cursor-pointer " +
                                (quickGameStore.index === (quickGameStore.levelStore.levelsQuickGame.length -1) && " scale-x-[-1]")}
                                     src={quickGameStore.index !== (quickGameStore.levelStore.levelsQuickGame.length -1) ? arrowRight : arrowLeft} alt="" />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
});

export default QuickMode;
