import Home from "../Home/Home";
import styles from './App.module.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import QuickModeSidebar from "../QuickModeSidebar/QuickModeSidebar";
import NormalModeSidebar from "../NormalModeSidebar/NormalModeSidebar";
import QuickMode from "../QuickMode/QuickMode";
import NormalMode from "../NormalMode/NormalMode";
import Score from "../Scores/Scores";
import { observer } from "mobx-react";
import { useLayoutEffect } from "react";
import HomeLeftAreaV3 from "Components/elements/common/freemium/HomeLeftArea";
import MenuV3 from "Components/elements/common/freemium/MenuV3";
import Cookies from "Components/elements/common/Cookies";

const App = observer(({ normalGameStore, levelStore, quickGameStore, userStore }: any) => {

    useLayoutEffect(() => {
        normalGameStore.initialize();
        levelStore.initialize();
    }, []);

    return (<>

        <Router>
            <div
                style={{ height: '100%' }}
                className={"flex w-full overflow-hidden justify-center items-center"}>

                <div className={styles.sidebar + ' w-[var(--sidebar-width)] h-[var(--main-content-height)] flex-shrink-0'} style={normalGameStore.isFullScreen || quickGameStore.isFullScreen ? { display: "none" } : {}}>

                    <div className="hp-layoutV2" style={{ transformOrigin: 'center top', zIndex: 99 }}>
                        <MenuV3 />
                    </div>

                    <Switch>
                        <Route path="/mode/rapide" render={(props) => (
                            <QuickModeSidebar {...props} quickGameStore={quickGameStore} />
                        )}
                        />
                        <Route path="/mode/normal" render={(props) => (
                            <NormalModeSidebar {...props} normalGameStore={normalGameStore} />
                        )}
                        />
                        <Route path="/score" render={() => (
                            <HomeLeftAreaV3 />
                        )}
                        />
                        <Route path="/" render={() => (
                            <HomeLeftAreaV3 />
                        )}
                        />
                    </Switch>

                </div>
                <div className={(normalGameStore.isFullScreen || quickGameStore.isFullScreen) ? ' w-full h-full' : " w-[var(--main-content-width)] h-[var(--main-content-height)] flex-shrink-0"}>
                    <Switch>
                        <Route path="/mode/rapide" render={(props) => (
                            <QuickMode {...props} quickGameStore={quickGameStore} userStore={userStore} />
                        )} />
                        <Route path="/mode/normal" render={(props) => (
                            <NormalMode {...props} normalGameStore={normalGameStore} userStore={userStore} />
                        )} />
                        <Route path="/scores" render={(props) => (
                            <Score {...props} quickGameStore={quickGameStore} userStore={userStore} />
                        )} />
                        <Route path="/" render={(props) => (
                            <Home {...props} normalGameStore={normalGameStore} quickGameStore={quickGameStore} userStore={userStore} />
                        )} />
                    </Switch>
                </div>
            </div>
            <Cookies />
        </Router>
    </>);
});


export default App;
