import styles from './Home.module.css';
import chooseMode from "../../images/choose-mode-text.svg"
import arrowChooseMode from "../../images/arrow-choose-mode.svg"
import quickModeImage from "../../images/mode-quick-game-image.svg"
import { ReactComponent as IconLock } from 'assets/v3/quiz-lock-icon.svg';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
//@ts-ignore
import navigationSound from "../../assets/sounds/navigation.mp3";
import useSound from 'use-sound';
import { UserContext } from 'Components/utils/UserContext';
import BlockedModal from 'Components/elements/common/freemium/BlockedModal';
import { BlockerContext, steps } from 'Components/utils/BlockerContext';


export const Home = observer(({ quickGameStore, normalGameStore }: any) => {
    const { block, setBlock, unblockStep, setUnblockStep, nextStep } = useContext(BlockerContext);
    const [chosenMode, setChosenMode] = useState("normal");
    const { userHasAccess } = useContext(UserContext);
    const [playNavigation] = useSound(navigationSound, { volume: 0.5 });

    useLayoutEffect(() => {
        normalGameStore.isFullScreen = false;
        quickGameStore.isFullScreen = false;
    })

    useEffect(() => {
        if (!userHasAccess() && chosenMode !== "rapide") setChosenMode("rapide");
    }, [chosenMode])

    return (
        <>
            <div className={styles.container + " w-full h-full hp-layoutV2"}>
                <div className={styles.whiteModeContainer}>
                    <div className="flex flex-col items-center flex-grow">
                        <img className="w-[397px] mb-[5px]" src={chooseMode} alt="" />
                        <div className="flex justify-center">
                            <img src={arrowChooseMode} className={chosenMode !== "normal" ? "opacity-50" : ""} alt="" />
                            <img src={arrowChooseMode} className={"scale-x-[-1] " + (chosenMode !== "rapide" && "opacity-50")} alt="" />
                        </div>
                        <div className="flex text-center text-[30px] flex-grow">
                            <div onClick={() => {

                                if (!userHasAccess()) {
                                    setBlock(true);
                                    setUnblockStep(steps.SUBSCRIPTION);
                                } else {
                                    setChosenMode("normal");
                                }
                                playNavigation();
                            }} className={"flex flex-col mr-[29px] cursor-pointer " + (chosenMode !== "normal" && "opacity-50")}>
                                <span className="mb-[8px] font-MarvinRound">PARCOURS</span>
                                <div className={"flex items-center justify-center flex-grow w-[320px] h-[316px] relative overflow-hidden " + (chosenMode === "normal" ? styles.borderSelection : "border-[4px] border-transparent rounded-[20px]")}>

                                    {
                                        normalGameStore.levelStore.isLoading && <ClipLoader size="40" />
                                    }
                                    {!normalGameStore.levelStore.isLoading && !userHasAccess() && (
                                        <div className={styles.overlay}>
                                            <IconLock />
                                        </div>
                                    )}
                                    <img src={!normalGameStore.levelStore.isLoading && (normalGameStore.levelAttempts.length === 0 ? normalGameStore.levelStore.levels[0]?.imageOriginal :
                                        normalGameStore.levelStore.levels[normalGameStore.levelAttempts.length - 1].imageOriginal)} className="rounded-[20px] absolute" alt="" />

                                    {
                                        <div className={"w-[143px] h-[61px] flex items-center justify-center mb-[4px] bg-white text-[30px] absolute bottom-0 rounded-[11px] border-[#DDDDDD] border-[2px]"}>
                                            <span className="font-FredokaOne">{!normalGameStore.levelStore.isLoading && (normalGameStore.levelAttempts.length === 0 ? 1 : normalGameStore.levelAttempts.length) + "/" + normalGameStore.levelStore.levels.length}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div onClick={() => { setChosenMode("rapide"); playNavigation() }} className={"flex flex-col cursor-pointer " + (chosenMode !== "rapide" && "opacity-50")}>
                                <span className="mb-[8px] font-MarvinRound">PARTIE RAPIDE</span>
                                <div className={"flex items-center flex-grow px-[3px] w-[320px] h-[316px] " + (chosenMode === "rapide" ? styles.borderSelection : "border-[4px] border-transparent rounded-[20px]")}>
                                    <img src={quickModeImage} className="" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[49px] text-white text-[37px] font-MarvinRound">
                        <Link onClick={() => playNavigation()} to={chosenMode === "normal" ? "/mode/normal" : "/mode/rapide"}>
                            <button className={` bg-[#4C7A79] w-full h-[88px] rounded-[21px] tracking-[0.02em]`}>{!(normalGameStore.index > 0 && chosenMode === "normal") ? "COMMENCER" : "REPRENDRE"}</button>
                        </Link>

                        <Link onClick={() => playNavigation()} to="/scores">
                            <button className="bg-[#9FA250] w-full h-[88px] rounded-[21px] mt-[24px] tracking-[0.02em]">TABLEAU DES CHAMPIONS</button>
                        </Link>
                    </div>
                </div>

                <BlockedModal open={block} setOpen={setBlock} unblockStep={unblockStep} setUnblockStep={setUnblockStep} nextStep={nextStep} />
            </div>

        </>
    );
});

export default Home;
