import {makeAutoObservable} from "mobx";
import LevelError from "./LevelError";


class Level {

    levelId: string|null;
    imageOriginal : any;
    imageError : any;
    title: string;
    description: string;
    subDescription: string;
    levelErrors: Array<LevelError>;
    status: string;
    visible: any;
    novel: any;

    constructor(title: string, description: string, subDescription: string, imageOriginal: any, levelErrors: Array<LevelError>,
         levelId: string|null = null, status: string = 'published', visible: any = true, novel: any) {

        makeAutoObservable(this);

        this.imageOriginal = imageOriginal;
        this.title = title;
        this.description = description;
        this.subDescription = subDescription;
        this.levelErrors = levelErrors;
        this.levelId = levelId;
        this.status = status;
        this.visible = visible;
        this.novel = novel;
    }

    static hydrate(level: any){
        return new Level(
            level.title,
            level.description,
            level.subDescription,
            level.imageOriginal,
            level.levelErrors.map((levelError: any) => LevelError.hydrate(levelError)), 
            level.levelId,
            level.status || undefined,
            level.visible || undefined,
            level.novel|| undefined
        );
    }
}

export default Level;