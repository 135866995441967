import {makeAutoObservable} from "mobx";

class LevelError {

    levelId: string|null;
    levelErrorKey: any;
    errorImage: string;
    x : number;
    y : number;
    width: number;
    height: number;
    useHint: boolean = false;

    constructor(image: any, x: number, y: number, width: number = 100, height: number = 100, levelId = null, levelErrorKey = null, useHint = false) {

        makeAutoObservable(this);

        this.errorImage = image;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.levelId = levelId;
        this.levelErrorKey = levelErrorKey;
        this.useHint = useHint;
    }

    static hydrate(levelError: any){
        return new LevelError(
            levelError.errorImage,
            parseFloat(levelError.x),
            parseFloat(levelError.y),
            parseFloat(levelError.width),
            parseFloat(levelError.height),
            levelError.levelId,
            levelError.levelErrorKey
        );
    }
}

export default LevelError;
