import ApiWrapper from 'DataStore/ApiWrapper';
import { TUserInfo, TScoreUser } from '../types'
import { API } from 'aws-amplify';

const apiName = 'ApiGatewayRestApi';

export default class BaseDS {

  static getRootProfil = async (): Promise<TUserInfo> => {
    return ApiWrapper.get(apiName, '/userInfo/getRootProfil');
  };

  static getProfil = async (profilKey: string): Promise<TUserInfo> => {
    return ApiWrapper.get(apiName, '/userInfo/getProfil/' + profilKey);
  };

  static sendScore = async (options: any ): Promise<TScoreUser> => {

    return ApiWrapper.post(apiName, '/score/sendScore/', { body: { options } });
  };

  static getAllScores = async (): Promise<any> => {

    return ApiWrapper.get(apiName, '/score/all/');
  };

  static updateLevelAttempts = async (options: any): Promise<any> => {

    return ApiWrapper.post(apiName, '/userInfo/updateLevelAttempts/', { body: { options } });
  };
  
  static getAllLevels = async (): Promise<any> => {

    return ApiWrapper.get(apiName, '/level/all/');
  };

  static getAllLevelErrors = async (): Promise<any> => {

    return ApiWrapper.get(apiName, '/levelError/all/');
  };

  static getAllNovels = async (): Promise<any> => {

    return ApiWrapper.get(apiName, '/novel/all/');
  };

  static getPlatformDef = async (): Promise<any> => {
    return API.get('PlatformRestApi', '/main/first', {});
  };

}

