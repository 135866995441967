import {makeAutoObservable} from "mobx";
import LevelAttempt from "../Entity/LevelAttempt";


class UserStore {

    user: any;

    constructor(user: any = null) {
        
        makeAutoObservable(this);

        if(user){
            user.levelAttempts = user.levelAttempts.map((levelAttempt: LevelAttempt) => LevelAttempt.hydrate(levelAttempt));
        }

        this.user = user;        
    }

    logUser(user: any){
        if(!user) user = {};
        if(!Array.isArray(user.levelAttempts)) user.levelAttempts = [];
        user.levelAttempts = user.levelAttempts.map((levelAttempt: LevelAttempt) => LevelAttempt.hydrate(levelAttempt));
        this.user = user;
    }
}

export default UserStore;