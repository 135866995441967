import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import styles from './index.module.css';
import './fonts/fonts.css'
import Amplify from '@aws-amplify/core';
import AppInitializer from './Components/App/AppInitializer';
import UserAnnouncement from './Common/phoneblocked/UserAnnouncement';
import loadImage from './Common/loadImage'
import backgroundHome from '../src/images/background-home.svg'
import backgroundScore from '../src/images/score-background.svg'
import scoreButton from '../src/images/go-button.svg'
import tableauDesScores from '../src/images/tableau-des-scores.svg'
import choisisTonMode from '../src/images/choose-mode-text.svg'
import arrow from '../src/images/arrow-choose-mode.svg'
import zoomOutIcon from '../src/images/zoom-out.svg'
import zoomInIcon from '../src/images/zoom-in-icon.svg'
import roundedArrow from '../src/images/rounded-arrow.svg'
import chercheLerreur from '../src/images/cherche-lerreur.svg'
import tropheeScore from '../src/images/trophy.svg'
import normalLevelPreview from '../src/images/normal-level-preview.svg'
import quickPreview from '../src/images/mode-quick-game-image.svg'
import errorIcon from '../src/images/error-icon.svg'
import goodIcon from '../src/images/good-icon.svg'
import goButton from "../src/images/go-button.svg"
import Secured from './Components/Secured'
import GA4React from 'ga-4-react'
import awsconfig from "./aws-exports";
import UserContextProvider from "Components/utils/UserContext";
import BlockerContextProvider from "Components/utils/BlockerContext";
import MainContextProvider from "Components/utils/MainContext";


loadImage(backgroundHome);
loadImage(backgroundScore);
loadImage(scoreButton);
loadImage(tableauDesScores);
loadImage(choisisTonMode);
loadImage(arrow);
loadImage(zoomOutIcon);
loadImage(zoomInIcon);
loadImage(roundedArrow);
loadImage(chercheLerreur);
loadImage(tropheeScore);
loadImage(normalLevelPreview);
loadImage(quickPreview);
loadImage(goodIcon);
loadImage(errorIcon);
loadImage(goButton);


Amplify.configure(awsconfig);


const calculateRealWidthPortrait = () => {
  const offset = 30;

  const height = window.innerHeight;
  const width = window.innerWidth;

  const vh = height;
  const vw = height / width > (1069 / (1821 + offset))
    ? width : (1821 + offset) / 1069 * height;

  const vmin = vh;
  const isLandscape = true//vh < vw;
  const leftAreaMinWidth = isLandscape ? 0.217 * vw : vw;
  const leftAreaHeight = isLandscape ? vmin : 0.4 * vw;
  const mainContentWidth = isLandscape
    ? (vw - leftAreaMinWidth < 4 / 3 * vh ? vw - leftAreaMinWidth : 4 / 3 * vh)
    : (vh - leftAreaHeight - 0.08 * vmin < vw ? vh - leftAreaHeight - 0.08 * vmin : vw);
  const leftAreaWith = isLandscape ? vw - mainContentWidth : vw;
  const headerWidth = isLandscape ? leftAreaWith : vw;
  const headerHeight = isLandscape ? 0.217 * leftAreaMinWidth : 0.08 * vmin;
  const mainContentHeight = isLandscape ? 3 / 4 * mainContentWidth : mainContentWidth;
  const finalLeftAreaHeight = isLandscape ? leftAreaHeight : vh - headerHeight - mainContentHeight;

  document.documentElement.style.setProperty('--content-width-real', `${leftAreaWith + 0.97 * mainContentWidth}px`);
  document.documentElement.style.setProperty('--content-height-real', `${0.97 * mainContentHeight}px`);
  document.documentElement.style.setProperty('--leftAreaMinWidth-real', `${leftAreaMinWidth}px`);

  document.documentElement.style.setProperty('--mainContentWidth-real', `${0.97 * mainContentWidth}px`);
  document.documentElement.style.setProperty('--mainContentHeight-real', `${0.97 * mainContentHeight}px`);
  document.documentElement.style.setProperty('--headerHeight-real', `${headerHeight}px`);
  document.documentElement.style.setProperty('--leftAreaWidth-real', `${leftAreaWith}px`);
  document.documentElement.style.setProperty('--vmin', `${vmin}px`);

}

window.addEventListener('resize', calculateRealWidthPortrait)

const calculateWidthPortrait = () => {
  const offset = 30;

  const height = 1100;
  const width = 1885.686//1864.79;

  const vh = height;
  const vw = height / width > (1069 / (1821 + offset))
    ? width : (1821 + offset) / 1069 * height;

  const vmin = vh;
  const isLandscape = true//vh < vw;
  const leftAreaMinWidth = isLandscape ? 0.217 * vw : vw;
  const leftAreaHeight = isLandscape ? vmin : 0.4 * vw;
  const mainContentWidth = isLandscape
    ? (vw - leftAreaMinWidth < 4 / 3 * vh ? vw - leftAreaMinWidth : 4 / 3 * vh)
    : (vh - leftAreaHeight - 0.08 * vmin < vw ? vh - leftAreaHeight - 0.08 * vmin : vw);
  const leftAreaWith = isLandscape ? vw - mainContentWidth : vw;
  const headerWidth = isLandscape ? leftAreaWith : vw;
  const headerHeight = isLandscape ? 0.217 * leftAreaMinWidth : 0.08 * vmin;
  const mainContentHeight = isLandscape ? 3 / 4 * mainContentWidth : mainContentWidth;
  const finalLeftAreaHeight = isLandscape ? leftAreaHeight : vh - headerHeight - mainContentHeight;

  document.documentElement.style.setProperty('--content-width', `${leftAreaWith + 0.97 * mainContentWidth}px`);
  document.documentElement.style.setProperty('--content-height', `${0.97 * mainContentHeight}px`);

  document.documentElement.style.setProperty('--finalLeftAreaHeight', `${finalLeftAreaHeight}px`);
  document.documentElement.style.setProperty('--leftAreaWidth', `${leftAreaWith}px`);
  document.documentElement.style.setProperty('--leftAreaMinWidth', `${leftAreaMinWidth}px`);
  document.documentElement.style.setProperty('--leftAreaHeight', `${leftAreaHeight}px`);
  document.documentElement.style.setProperty('--mainContentWidth', `${0.97 * mainContentWidth}px`);
  document.documentElement.style.setProperty('--mainContentHeight', `${0.97 * mainContentHeight}px`);
  document.documentElement.style.setProperty('--headerHeight', `${headerHeight}px`);
  document.documentElement.style.setProperty('--headerWidth', `${headerWidth}px`);
  document.documentElement.style.setProperty('--vmin', `${vmin}px`)
  document.documentElement.style.setProperty('--vw', `${vw}px`)
  document.documentElement.style.setProperty('--vh', `${vh}px`)



}


(async () => {
  if (process.env.REACT_APP_GA_ID
    && process.env.REACT_APP_GA_ID !== '') {
    console.log('Initialise google analytics');
    const ga4react = new GA4React(process.env.REACT_APP_GA_ID);
    try {
      await ga4react.initialize();
    } catch (err) {
      console.error(err);
    }
  }


  window.addEventListener('resize', calculateWidthPortrait)


  ReactDOM.render(

    <React.StrictMode>

      <UserContextProvider>
        <BlockerContextProvider>
          <MainContextProvider>
            <UserAnnouncement />
            <div style={{ justifyContent: "center" }} id="content-root" className={styles.contentRoot}>
              <Secured>
                <AppInitializer></AppInitializer>
              </Secured>
            </div>
          </MainContextProvider>
        </BlockerContextProvider>
      </UserContextProvider>
    </React.StrictMode>,

    document.getElementById('root')
  );



  calculateWidthPortrait();
  calculateRealWidthPortrait();

  let root = document.getElementById('content-root');

  let rootStyle = getComputedStyle(root);

  function setScale() {
    document.documentElement.style.setProperty('--main-content-height', (parseFloat(document.documentElement.style.getPropertyValue('--mainContentHeight-real')) + 'px'));

    const initialWidth = parseFloat(rootStyle.width);
    const initialHeight = parseFloat(rootStyle.height);
    const targetWidth = parseFloat(document.documentElement.style.getPropertyValue('--content-width-real'));
    const targetHeight = parseFloat(document.documentElement.style.getPropertyValue('--mainContentHeight-real'));
    const scale = targetWidth / initialWidth

    document.documentElement.style.setProperty('--sidebar-width', (parseFloat(document.documentElement.style.getPropertyValue('--leftAreaMinWidth-real')) / scale + 'px'));
    document.documentElement.style.setProperty('--main-content-width', (parseFloat(document.documentElement.style.getPropertyValue('--mainContentWidth-real')) / scale + 'px'));
    document.documentElement.style.setProperty('--main-content-height', (parseFloat(document.documentElement.style.getPropertyValue('--mainContentHeight-real')) / scale + 'px'));
    document.documentElement.style.setProperty('--header-height', (parseFloat(document.documentElement.style.getPropertyValue('--headerHeight-real')) + 'px'));

    document.documentElement.style.setProperty('--scale', scale);
  }

  setScale();
  window.addEventListener('resize', setScale);


})();
