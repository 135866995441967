import "./HomeLeftArea.scss";
import HooPowAppMenuV3 from "Components/elements/common/internalrouter/HooPowAppMenuV3";
import { ReactComponent as HelpUsBtn } from "assets/v3/fg-helpus-btn.svg";
import { ReactComponent as FoulaneDiffLogo } from "assets/v3/foulane-diff-logo.svg";
import { useContext } from "react";
import { UserContext } from "Components/utils/UserContext";
import { BlockerContext } from "Components/utils/BlockerContext";

const HomeLeftAreaV3 = () => {
	const { setBlock } = useContext(BlockerContext);
	const { freemiumEnabled, userHasAccess, loadingUserAccess } = useContext(UserContext);

	return (
		<>
			{process.env.REACT_APP_FEATURE_PAUSE !== '1' && freemiumEnabled && !userHasAccess() && !loadingUserAccess && (
				<HelpUsBtn  className="ms-home-helpus ms-clickable" onClick={() => setBlock((prev: boolean) => !prev)}/>
			)}
			<div className="ms-home-icon-v3"> <FoulaneDiffLogo /> </div>
			<HooPowAppMenuV3 />
		</>
	);
};

export default HomeLeftAreaV3;
