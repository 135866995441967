import HooPowDS from "Components/elements/common/internalrouter/HooPowDS";
import { createContext, useEffect, useState } from "react";

export const MainContext = createContext<any>("");

const MainContextProvider = (props: any) => {
	const [applications, setApplications] = useState<any[]>();

	useEffect(() => {
		HooPowDS.getPlatformDef().then((platformDef: any) => {
			setApplications(platformDef.application.filter((appDef: any) => appDef.publicationDate < new Date().toISOString()));
		});
	}, []);

	const getAppConfig = () => {
		return applications?.filter(app => app.appId === process.env.REACT_APP_ID)[0]
	}

	return (
		<MainContext.Provider value={{ getAppConfig, applications, setApplications }} >
			{props.children}
		</MainContext.Provider>
	);
};

export default MainContextProvider;
