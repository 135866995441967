import { makeAutoObservable } from "mobx";

class CommonStore {

    applications: any;

    constructor() {
        makeAutoObservable(this);
    }
}

export default CommonStore;