import {makeAutoObservable} from "mobx";


class Novel {

    id: string|null;
    name: string;
    link : string;
    posterVideo : string;

    constructor(id: string|null = null, name: string, link: string, posterVideo: string) {

        makeAutoObservable(this);

        this.id = id;
        this.name = name;
        this.link = link;
        this.posterVideo = posterVideo;
    }

    static hydrate(novel: any){
        return new Novel(
            novel.id,
            novel.name,
            "https://foulane.hoo-pow.com/famille-foulane/" + novel.id,
            process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL + "/" + novel.posterVideo
        );
    }
}

export default Novel;