import React, { useContext } from 'react';

import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { routeToPlatformHomePage } from './elements/common/internalrouter/HooPowAppMenuV3';
import { UserContext } from './utils/UserContext';

const signUpFields = [
  {
    type: "name",
    label: "Name",
    placeholder: "Enter your name",
    hint: null,
    required: true,
  },
  {
    type: "email",
    label: "Email Address *",
    required: true,
  },
  {
    type: "password",
    label: "Password *",
    required: true,
  },
];


function Secured(props: any) {
  const { freemiumEnabled, isUserAuthenticated, userHasAccess } = useContext(UserContext);
  React.useEffect(() => {
    if (!freemiumEnabled && (isUserAuthenticated === false || !userHasAccess())) {
      if (process.env.REACT_APP_DOMAIN_NAME === 'localhost') {
        alert('User blocked');
      } else {
        document.location.href = routeToPlatformHomePage();
      }
    }
  }, [isUserAuthenticated, freemiumEnabled, userHasAccess]);

  const redirectToHooPow = () => {
    document.location.href = routeToPlatformHomePage();
  }

  return (
    <>
      {isUserAuthenticated || freemiumEnabled ? (
        <>{props.children}</>
      ) : (
        <>
          {(isUserAuthenticated === undefined || process.env.REACT_APP_DOMAIN_NAME === 'localhost') ? (
            <AmplifyAuthenticator usernameAlias="email">
              <AmplifySignUp
                usernameAlias="email"
                formFields={signUpFields}
                slot="sign-up"
              />
            </AmplifyAuthenticator>
          ) : redirectToHooPow()}
        </>)}
    </>
  );
}

export default Secured;
